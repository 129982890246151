/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 8/21/2020
 * @Example
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {Col, Row, FormGroup, Image} from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import moment from 'moment';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography
} from "@material-ui/core";
import Attachments from "../../../common/attachments/attachments";
import _ from "lodash";
import {browserHistory} from "react-router";
import IconButton from "@material-ui/core/IconButton";
import Icons from "../../../common/icons";
import * as W77F1013Actions from "../../../../redux/W7X/W77F1013/W77F1013_actions";
import W77F1013TableCostView from "./W77F1013TableCostView";
import W77F1013TableAllocation from "./W77F1013TableAllocation";
import W77F1013TableRecCost from "./W77F1013TableRecCost";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
const styles = theme => {
    return {
        heading: {
            fontSize: 14,
            fontWeight: 'bold',
            marginRight: 5
        },
        noneBorder: {
            margin:'0px!important',
            "&:before": {
                backgroundColor: "transparent"
            }
        },
        ExpansionPanelSummary: {
            padding: 0,
            maxWidth: 300,
            color: theme.palette.primary.main,
            "& .MuiExpansionPanelSummary-content": {
                margin: "12px 0"
            },
            "&.Mui-expanded": {
                minHeight: "inherit",
            },

        },
        dateInfo: {
            display: "flex",
            alignItems: "center"
        },
        divText: {
            fontSize: "14px",
            fontWeight: 400,
            color: "#575757",
            whiteSpace: 'pre-line'
        },
        divDateText: {
            fontSize: "14px",
            fontWeight: 400,
            color: "#000000"
        },
        labelTotal: {
            margin: 0,
            fontWeight: 500,
            fontSize: 14,
        }
    };
};

class W77F1013 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataTotal:0,
            dataHide: [],
            iPermission: 0,
            error: {},
            modeTableRestCost: "",
            modeTableCostAllocation: "",
            loadingProjects: false,
            expandedFirst: false,
            expandedSecond: false,
            expandedThird: false,
            count: null,
            isSave: false,
            disabled: false,
            dataCostWorkPlansZero: [],
            dataCostWorkPlansOne: [],
            dataWorkingDiary: [],
            disableGridInfo: false,
            TotalAmountVND: 0,
            TotalAmountUSD: 0,
            dataTotalAllocation: 0,
            ReimburseVND: 0,
            ReimburseUSD: 0,
            totalCompanyPay: {
                VND: 0,
                USD: 0
            },
            employeePay : {
                VND: 0,
                USD: 0
            },
            formData: {
                Employee: null,
                FromDate: null,
                ToDate: null,
                BusinessTravelCode: "",
                BusinessLocation: "",
                IsDomestic: 0,
                Content: "",
                ProAmountVND: 0,
                ProAmountUSD: 0,
                ProDateAdvance: "",
                Reason: ""
            },
            dataOldAttachments: [],
            uploading: false,
            formLoading: false,
        };

        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.dataGridTableRestCost = null;
        this.dataGridTableCostAllocation = null;
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});

    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = e => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map(d => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    loadAttachments = () => {
        const { TransID } = this.getInfo();
        const param = {
            KeyID: TransID ? TransID : "",
            TableName: "D15T2051"
        };
        if (_.isEmpty(param.KeyID)) {
            Config.popup.show("ERROR", Config.lang("DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem"));
            return false;
        }
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if (data) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = () => {
        const { data } = this.getInfo();
        let arrRestCost = [];
        let arrCostAllocation = [];

        if (this.dataGridTableRestCost) {
            this.dataGridTableRestCost.instance.saveEditData();
            let dataRestCost = this.dataGridTableRestCost.instance.option("dataSource");
            dataRestCost.forEach(item => {
                dataRestCost = {
                    RecCostID: item.RecCostID,
                    AmountVND: item.AmountVND,
                    AmountUSD: item.AmountUSD,
                    IsCompanyPay: item.IsCompanyPay,
                    Note: item.Note
                };
                arrRestCost.push(dataRestCost)
            })

        }
        if (this.dataGridTableCostAllocation) {
            this.dataGridTableCostAllocation.instance.saveEditData();
            let dataCostAllocation  = this.dataGridTableCostAllocation.instance.option("dataSource");
            dataCostAllocation.forEach(item => {
                dataCostAllocation = {
                    AllocationID: item.ProjectID,
                    Amount: item.Amount,
                    Note: item.Note,
                };
                arrCostAllocation.push(dataCostAllocation)
            })
        }
        const { location } = this.props;
        const TransID = _.get(location, "state.TransID", "");

        const arrAttachments = this._getAttachments();
        let params = {
            TransID: TransID ? TransID : "",
            FormID: "W77F1013",
            ModuleID: "D77",
            Language: Config.language || 84,
            TransTypeID: "",
            Mode: data?.StatusRegisterEX === 1 ? 1 : 0,
            StatusApproval: 0,
            ApprovalLevel: 1,
            TransactionID: "W77DKQTCP",
            costs: arrRestCost ? JSON.stringify(arrRestCost) : "",
            diaries: arrCostAllocation ? JSON.stringify(arrCostAllocation) : "",
            arrAttachment: arrAttachments ? JSON.stringify(arrAttachments) : ""
        };
        this.setState({ formLoading: true });
        this.props.w77F1013Actions.add(params, (errors, data) => {
            this.setState({ formLoading: false });
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
            if (data.Status === 1) {
                const msg = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', msg);
                return false;
            } else {
                Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                this.setState({isSave: true});
            }

        });
    };

    getInfo = (flag, nextProps) => {
        const { location } = nextProps ? nextProps : this.props;
        const { data,mode } = this.props;
        if (location && location.state) {
            const voucher_id = location.state.voucher_id ? location.state.voucher_id : "";
            return {
                voucherID: voucher_id,
                mode: location.state.mode ? location.state.mode : (voucher_id ? 'edit' : 'add'),
                isPermissionForm: location.state.isPermissionForm ? location.state.isPermissionForm  : "",
                TransID: location.state.TransID ? location.state.TransID  : "",
                data: location.state.data ? location.state.data  : "",
            }
        } else {
            if(data) {
                return {
                    mode: mode,
                    TransID: data?.TransID ?? "",
                }
            }
            else if (flag) {
                browserHistory.push(Config.getRootPath() + "W77F1010");
                return null;
            }
            return false;
        }
    };

    loadCboCostTypes = () => {
        this.props.w77F1013Actions.getCboCostTypes(error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadFieldHide = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1013"
        };

        this.props.w77F1013Actions.getFieldHide(params,error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadInfoBusinessTrips = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1013",
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1013Actions.getInfoBusinessTrips(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({formData: {
                        ...this.state.formData,
                        Employee: data.EmployeeID,
                        FromDate: data.FromDate,
                        ToDate: data.ToDate,
                        BusinessTravelCode: data.BusinessTravelCode,
                        BusinessLocation: data.BusinessLocation,
                        IsDomestic: data.IsDomestic,
                        Content: data.Content,
                        ProAmountVND: data.ProAmountVND,
                        ProAmountUSD: data.ProAmountUSD,
                        ProDateAdvance: data.ProDateAdvance,
                        Reason: data.Reason
                    }});
            }
        });
    };

    loadCostWorkPlansZero = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1013",
            Mode: 0,
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1013Actions.getCostWorkPlansZero(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({dataCostWorkPlansZero: data});
            }
        });
    };

    loadCostWorkPlansOne = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1013",
            Mode: 1,
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1013Actions.getCostWorkPlansOne(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                let totalCompanyPayVND = 0;
                let totalCompanyPayUSD = 0;
                let TotalAmountVND = 0;
                let TotalAmountUSD = 0;
                let EmployeePayVND = 0;
                let EmployeePayUSD = 0;
                data.forEach(item => {
                    TotalAmountVND += (item.AmountVND || 0);
                    TotalAmountUSD += (item.AmountUSD || 0);
                    if (item.IsCompanyPay) {
                        totalCompanyPayVND += (item.AmountVND || 0);
                        totalCompanyPayUSD += (item.AmountUSD || 0);
                    }
                    else {
                        EmployeePayVND += (item.AmountVND || 0);
                        EmployeePayUSD += (item.AmountUSD || 0);
                    }
                });

                this.setState({TotalAmountVND:TotalAmountVND,TotalAmountUSD:TotalAmountUSD,totalCompanyPay: {VND: totalCompanyPayVND, USD: totalCompanyPayUSD}, employeePay: {VND: EmployeePayVND, USD: EmployeePayUSD}, dataCostWorkPlansOne: data},() => {
                    const {TotalAmountVND, TotalAmountUSD, totalCompanyPay, formData} = this.state;
                    const { ProAmountVND, ProAmountUSD } = formData;
                    let ReimburseVND = 0;
                    let ReimburseUSD = 0;
                    ReimburseVND = TotalAmountVND - totalCompanyPay.VND - ProAmountVND;
                    ReimburseUSD = TotalAmountUSD - totalCompanyPay.USD - ProAmountUSD;
                    this.setState({ReimburseVND:ReimburseVND,ReimburseUSD:ReimburseUSD});
                });
            }
        });
    };

    loadGetWorkingDiary = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1013",
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1013Actions.getWorkingDiary(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({dataWorkingDiary: data});
            }
        });
    };

    loadCostAllocation = () => {
        const { TransID, voucherID } = this.getInfo();
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W77F1013",
            TransID: TransID ? TransID : voucherID,
            Language: Config.language || 84
        };

        this.props.w77F1013Actions.getCostAllocation(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({dataCostAllocation: data});
            }
        });
    };

    loadConvertibleValueCurrentcies = (data, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            Mode: data ? data.Mode : 0,
            CurrencyID: data ? data.CurrencyID : "",
            Amount: data ? data.Amount : 0
        };
        this.props.w77F1013Actions.getConvertibleValueCurrentcies(params,(error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                cb && cb(error, data);
            }

        });
    };

    componentDidMount = () => {
        if (!this.getInfo(true)) return;
        const { data } = this.getInfo();
        if(data && data.AppStatusExID > 0) {
            this.setState({disabled: true})
        }
        this.loadInfoBusinessTrips();
        this.loadCostWorkPlansZero();
        this.loadCostWorkPlansOne();
        this.loadGetWorkingDiary();
        this.loadAttachments();
        this.loadCostAllocation();
        this.loadCboCostTypes();
        this.loadFieldHide();
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W77F1010");
    };

    onAddNewTableCostAllocation = () => {
        this.setState({modeTableCostAllocation: "add", expandedThird: true}, () => {
            setTimeout(() => {
                if (this.dataGridTableCostAllocation) {
                    this.dataGridTableCostAllocation.instance.addRow();
                }
            }, 300);
        });
    };

    onAddNewTableRestCost = () => {
        this.setState({modeTableRestCost: "add", expandedSecond: true}, () => {
            setTimeout(() => {
                if (this.dataGridTableRestCost) {
                    this.dataGridTableRestCost.instance.addRow();
                }
            }, 300);
        });
    };

    scrollTopButton = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    renderEmpProfile = () => {
        const { classes } = this.props;
        const { data } = this.getInfo();
        const date = data && data.ProposalDate && moment.utc(data.ProposalDate).format("DD/MM/YYYY, HH:mm:ss");
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.divText}>{data?.TransTypeID || ""}</div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{ paddingLeft: "8px" }} className={classes.divDateText}>
                            {date || ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    calculateCompanyPay = () => {
        if (this.dataGridTableRestCost) {
            const dataSource = this.dataGridTableRestCost.instance.option("dataSource");
            let totalCompanyPayVND = 0;
            let totalCompanyPayUSD = 0;
            let TotalAmountVND = 0;
            let TotalAmountUSD = 0;
            let EmployeePayVND = 0;
            let EmployeePayUSD = 0;
            dataSource.forEach(data => {
                TotalAmountVND += (data.AmountVND || 0);
                TotalAmountUSD += (data.AmountUSD || 0);
                if (data.IsCompanyPay) {
                    totalCompanyPayVND += (data.AmountVND || 0);
                    totalCompanyPayUSD += (data.AmountUSD || 0);
                }
                else {
                    EmployeePayVND += (data.AmountVND || 0);
                    EmployeePayUSD += (data.AmountUSD || 0);
                }
            });

            this.setState({TotalAmountVND:TotalAmountVND,TotalAmountUSD:TotalAmountUSD,totalCompanyPay: {VND: totalCompanyPayVND, USD: totalCompanyPayUSD}, employeePay: {VND: EmployeePayVND, USD: EmployeePayUSD}},() => {
                const {TotalAmountVND, TotalAmountUSD, totalCompanyPay, formData} = this.state;
                const { ProAmountVND, ProAmountUSD } = formData;
                let ReimburseVND = 0;
                let ReimburseUSD = 0;
                ReimburseVND = TotalAmountVND - totalCompanyPay.VND - ProAmountVND;
                ReimburseUSD = TotalAmountUSD - totalCompanyPay.USD - ProAmountUSD;
                this.setState({ReimburseVND:ReimburseVND,ReimburseUSD:ReimburseUSD});
            });
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { data, voucherID, mode } = this.getInfo();
        const {classes, getCboCostTypes, getFieldHide} = this.props;
        const { expandedFirst, expandedSecond, expandedThird,
            dataOldAttachments, formLoading, modeInfo, dataCostWorkPlansZero, dataCostWorkPlansOne, isSave, dataCostAllocation,
            formData, TotalAmountVND, TotalAmountUSD, totalCompanyPay, ReimburseVND, ReimburseUSD, disabled, employeePay } = this.state;
        const { FromDate, ToDate, BusinessTravelCode, BusinessLocation, Content, ProAmountVND, ProAmountUSD, ProDateAdvance, Reason } = formData;
        // const {UseCurency} = getFieldHide;
        return (
            <>
                {mode !== "view" && <ActionToolbar  title={Config.lang("DHR_Dang_ky_quyet_toan_chi_phi_cong_tac")} alignment={"flex-end"} onBack={this.onBack}>
                    <ButtonGeneral name={Config.lang('DHR_Luu')}
                                   typeButton={'save'}
                                   disabled={formLoading || isSave || disabled || (data && data.AppStatusExID && Number(data.AppStatusExID) !== 0) || !!voucherID}
                                   loading={formLoading}
                                   style={{ textTransform: 'uppercase' }}
                                   size={"large"}
                                   onClick={this.onSave} />
                </ActionToolbar>}
                <div className={"pdt10 pdb10 display_row align-center flex-wrap"}>

                    <Typography style={{fontSize: "15px", fontWeight:"bold"}}>
                        {Config.lang("DHR_Thong_tin_chung")}
                    </Typography>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {this.renderEmpProfile()}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <FormGroup>
                                    <Row>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <label className={"no-margin"}>{Config.lang("DHR_Ma_cong_tac")}</label>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={8} md={8} lg={8}>
                                            <span>{BusinessTravelCode && BusinessTravelCode}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <label className={"no-margin"}>{Config.lang("DHR_Dia_diem")}</label>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={8} md={8} lg={8}>
                                            <span>{BusinessLocation && BusinessLocation}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <label className={"no-margin"}>{Config.lang("DHR_Ngay_di/ve")}</label>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={8} md={8} lg={8}>
                                            <span>{FromDate &&  ToDate && `${moment(FromDate).format("DD/MM/YYYY")} - ${moment(ToDate).format("DD/MM/YYYY")}`}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <label className={"no-margin"}>{Config.lang("DHR_Muc_dich_cong_tac")}</label>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={8} md={8} lg={8}>
                                            <span>{Content && Content}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>

                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <FormGroup>
                                    <Row>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <label className={"no-margin"}>{Config.lang("DHR_Tien_chi_tam_ung")}</label>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <span>{ ProAmountVND && new Intl.NumberFormat().format(ProAmountVND) + ' đ'}</span>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <span>{ProAmountUSD && `${ProAmountUSD}$`}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <label className={"no-margin"}>{Config.lang("DHR_Ngay_de_xuat_tam_ung")}</label>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={8} md={8} lg={8}>
                                            <span>{ProDateAdvance && moment(ProDateAdvance).format("DD/MM/YYYY")}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={4} md={4} lg={4}>
                                            <label className={"no-margin"}>{Config.lang("DHR_Ly_do_tam_ung")}</label>
                                        </Col>
                                        <Col style={{ textAlign: "left" }} xs={12} sm={8} md={8} lg={8}>
                                            <span>{Reason && Reason}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                {/*<Container maxWidth="lg" style={{ paddingTop: 20, marginLeft: 0 }}>*/}
                <ExpansionPanel
                    expanded={expandedFirst}
                    className={classes.noneBorder}
                    style={{ boxShadow: "none" }}
                    onChange={() => this.setState({ expandedFirst: !expandedFirst })}
                >
                    <div className={"display_row align-center"}>
                        <ExpansionPanelSummary
                            className={classes.ExpansionPanelSummary}
                        >
                            <i
                                className={"fa display_row align-center " + (expandedFirst ? "fa-angle-down" : "fa-angle-right")}
                                style={{ fontSize: 20, width: 20 }}
                            />
                            <Typography className={classes.heading}>{Config.lang("DHR_Khoan_chi_phi_du_kien")}</Typography>
                        </ExpansionPanelSummary>

                    </div>

                    <ExpansionPanelDetails style={{ padding: 0,width: "100%" }}>
                        <W77F1013TableCostView
                            dataCostWorkPlansZero={dataCostWorkPlansZero}
                            modeInfo={modeInfo}
                            getFieldHide={getFieldHide}
                            getCboCostTypes={getCboCostTypes}
                            getConvertibleValueCurrentcies={this.loadConvertibleValueCurrentcies}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                    expanded={expandedSecond}
                    className={classes.noneBorder}
                    style={{ boxShadow: "none"}}
                    onChange={() => this.setState({ expandedSecond: !expandedSecond })}
                >
                    <div className={"display_row align-center"}>
                        <ExpansionPanelSummary
                            className={classes.ExpansionPanelSummary}
                        >
                            <i
                                className={"fa display_row align-center " + (expandedSecond ? "fa-angle-down" : "fa-angle-right")}
                                style={{ fontSize: 20, width: 20 }}
                            />
                            <Typography className={classes.heading}>{Config.lang("DHR_Khoan_de_nghi_quyet_toan")}</Typography>
                        </ExpansionPanelSummary>
                        <ButtonGeneral
                            typeButton={"add"}
                            size={"small"}
                            disabled={disabled || !!voucherID || mode === "view" }
                            variant={"outlined"}
                            onClick={this.onAddNewTableRestCost}
                        />
                    </div>
                    <ExpansionPanelDetails style={{ padding: 0,width: "100%" }}>
                        <W77F1013TableRecCost
                            gridRef={ref => this.dataGridTableRestCost = ref}
                            dataCostWorkPlansOne={dataCostWorkPlansOne}
                            getFieldHide={getFieldHide}
                            getCboCostTypes={getCboCostTypes}
                            getConvertibleValueCurrentcies={this.loadConvertibleValueCurrentcies}
                            calculateCompanyPay={this.calculateCompanyPay}
                            onAddNewTableRestCost={this.onAddNewTableRestCost}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                    expanded={expandedThird}
                    className={classes.noneBorder}
                    style={{ boxShadow: "none"}}
                    onChange={() => this.setState({ expandedThird: !expandedThird })}
                >
                    <div className={"display_row align-center"}>
                        <ExpansionPanelSummary
                            className={classes.ExpansionPanelSummary}
                        >
                            <i
                                className={"fa display_row align-center " + (expandedThird ? "fa-angle-down" : "fa-angle-right")}
                                style={{ fontSize: 20, width: 20 }}
                            />
                            <Typography className={classes.heading}>{Config.lang("DHR_Phan_bo_chi_phi")}</Typography>
                        </ExpansionPanelSummary>
                        <ButtonGeneral
                            typeButton={"add"}
                            size={"small"}
                            disabled={disabled || !!voucherID || mode === "view" }
                            variant={"outlined"}
                            onClick={this.onAddNewTableCostAllocation}
                        />
                    </div>
                    <ExpansionPanelDetails style={{ padding: 0,width: "100%" }}>
                        <W77F1013TableAllocation
                            gridRef={ref => this.dataGridTableCostAllocation = ref}
                            dataCostAllocation={dataCostAllocation}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/*</Container>*/}
                <Typography className={classes.heading} style={{margin:"20px 0"}}>{Config.lang("DHR_Tong_hop_chi_phi")}</Typography>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <FormGroup>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs={12} sm={6} md={6} lg={6}>
                                    <label className={classes.labelTotal}>{'(1)' + Config.lang("DHR_Tong_chi_phi_thuc_te_chuyen_di")}</label>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{TotalAmountVND && new Intl.NumberFormat().format(TotalAmountVND) + ' đ'}</span>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{TotalAmountUSD &&   `${ new Intl.NumberFormat(
                                        { style: 'currency', currency: 'USD' }
                                    ).format(TotalAmountUSD)} $`}</span>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs={12} sm={6} md={6} lg={6}>
                                    <label className={classes.labelTotal}>{'(2)' + Config.lang("DHR_Tong_chi_phi_nhan_vien_su_dung")}</label>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{employeePay && new Intl.NumberFormat().format(employeePay.VND) + ' đ'}</span>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{employeePay &&  `${ new Intl.NumberFormat(
                                        { style: 'currency', currency: 'USD' }
                                    ).format(employeePay.USD)} $`}</span>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs={12} sm={6} md={6} lg={6}>
                                    <label className={classes.labelTotal}>{'(3)' + Config.lang("DHR_Tong_chi_phi_cong_ty_thanh_toan")}</label>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span> {totalCompanyPay && totalCompanyPay.VND && new Intl.NumberFormat().format(totalCompanyPay.VND) + ' đ'}</span>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span> {totalCompanyPay && totalCompanyPay.USD && `${ new Intl.NumberFormat(
                                        { style: 'currency', currency: 'USD' }
                                    ).format(totalCompanyPay.USD)} $`}</span>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs={12} sm={6} md={6} lg={6}>
                                    <label className={classes.labelTotal}>{'(4)' + Config.lang("DHR_Chi_tam_ung")}</label>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{ProAmountVND && new Intl.NumberFormat().format(ProAmountVND) + ' đ'}</span>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{ProAmountUSD && `${ new Intl.NumberFormat(
                                        { style: 'currency', currency: 'USD' }
                                    ).format(ProAmountUSD)} $`}</span>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs={12} sm={6} md={6} lg={6}>
                                    <label className={classes.labelTotal}>{'(5)' + Config.lang("DHR_Chi_phi_con_lai_can_quyet_toan")}</label>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{ReimburseVND && new Intl.NumberFormat().format(ReimburseVND) + ' đ'}</span>
                                </Col>
                                <Col style={{ textAlign: "left" }} xs={12} sm={3} md={3} lg={3}>
                                    <span>{ReimburseUSD && `${ new Intl.NumberFormat(
                                        { style: 'currency', currency: 'USD' }
                                    ).format(ReimburseUSD)} $`}</span>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs={12} sm={6} md={6} lg={6}>
                                    <label style={{fontSize:"13px",color:"gray",fontStyle:"italic"}}>{'*' + Config.lang("DHR_Ghi_chu")}</label>
                                    <p style={{fontSize:"12px",color:"gray",fontStyle:"italic"}}>{'(1) =(2)+(3)'}</p>
                                    <p style={{fontSize:"12px",color:"gray",fontStyle:"italic"}}>{'(5) =(1)-(3)-(4)'}</p>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{zIndex: 1, marginTop: 25}}>
                            <Attachments
                                isAttachInfo
                                ref={ref => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={formLoading || isSave || (data && data.AppStatusExID && Number(data.AppStatusExID) !== 0) || disabled || !!voucherID || mode === "view"}
                                maxLength={5}
                                uploading={formLoading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <ButtonGeneral name={Config.lang('DHR_Luu')}
                               typeButton={'save'}
                               disabled={formLoading || isSave || disabled || (data && data.AppStatusExID && Number(data.AppStatusExID) !== 0) || !!voucherID || mode === "view"}
                               loading={formLoading}
                               style={{ textTransform: 'uppercase',float: 'right' }}
                               size={"large"}
                               onClick={this.onSave} />

                <ActionToolbar alignment={"flex-end"} className={mode === "view" ? "hidden" : "" } style={{border: 'none'}}>
                    <IconButton aria-label="view" style={{position: "fixed", bottom: 20, zIndex: 10}} className={"btn-grid-action mgr5"}
                                size={"medium"}
                                onClick={this.scrollTopButton}
                    >
                        <Icons name={"up_filled"} width={"30px"} style={{marginTop: "10px"}} height={"30px"}/>
                    </IconButton>
                </ActionToolbar>
            </>
        )
    }
}

export default compose(
    connect(
        state => ({
            getCboCostTypes: state.W77F1013.getCboCostTypes,
            getFieldHide: state.W77F1013.getFieldHide,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w77F1013Actions: bindActionCreators(W77F1013Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(W77F1013);