import React, { Component } from "react";
import Config from "../../../../config";
import Api from "../../../../services/api";

import GridContainer from "../../../grid-container/grid-container";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { FormGroup, Col, Row } from "react-bootstrap";
import _ from "lodash";

import { Column } from "devextreme-react/data-grid";
import ButtonGeneral from "../../../common/button/button-general";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import * as W77F1000Actions from "../../../../redux/W77/W77F1000/W77F1000_actions";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "../../../common/modal/modal";
import { IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import * as generalActions from "../../../../redux/general/general_actions";
import Icons from "../../../common/icons";
import W71F1001 from "../W77F1001/W77F1001";
import GridActionBar from "../../../grid-container/grid-actionbar";
const styles = {
    buttonAction: {
        width: 100,
    },
};
class W77F1000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "add",
            gridLoading: false,
            isCheckDisabled: false,
            iPermission: 0,
            showW71F1001: false,
            rowData: [],
        };
        this.paramFilters = {
            SearchValue: "",
            skip: 0,
            limit: 10,
        };
        this.timer = null;
        this.isSaveContinue = false;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W77F1000", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadGrid = () => {
        const { isCheckDisabled } = this.state;
        const params = {
            ...this.paramFilters,
            Disabled: isCheckDisabled ? 2 : 0,
        };
        this.setState({ gridLoading: true });
        this.props.w77F1000Actions.loadGrid(params, (err) => {
            this.setState({ gridLoading: false });
        });
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            rowData: [],
            showW71F1001: true,
        });
        this.isSaveContinue = false;
    };

    onView = (e) => {
        this.setState({
            mode: "view",
            rowData: _.get(e, "data", []),
            showW71F1001: true,
        });
        this.isSaveContinue = false;
    };

    onEdit = (e) => {
        this.setState({
            mode: "edit",
            rowData: _.get(e, "data", []),
            showW71F1001: true,
        });
        this.isSaveContinue = false;
    };

    onDelete = (e) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            (async () => {
                this.setState({ gridLoading: true });
                const RecCostID = _.get(e, "data.RecCostID", "");
                const res = await Api.put("/w77f1000/delete", { RecCostID });
                this.setState({ gridLoading: false });
                const messageErr = _.get(res, "message", "") || _.get(res, "data.Message", "");
                const status = _.get(res, "data.Status", 1);
                if (messageErr || status) return Config.popup.show("INFO", messageErr);
                if (!status) {
                    this.loadGrid();
                    return Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                }
            })();
        });
    };

    onCloseModal = (isSaved = false) => {
        if (isSaved || this.isSaveContinue) this.loadGrid();
        this.setState({
            showW71F1001: false,
        });
    };

    onSearch = (e) => {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.paramFilters.SearchValue = _.get(e, "target.value", "");
            this.loadGrid();
        }, 500);
    };

    onChangePage = (page) => {
        this.paramFilters.skip = page * this.paramFilters.limit;
        this.loadGrid();
    };

    onChangePerPage = (perPage) => {
        this.paramFilters.skip = 0;
        this.paramFilters.limit = perPage;
        this.loadGrid();
    };

    renderButtonAction = (e) => {
        const { iPermission } = this.state;
        const arrIcon = [
            {
                iconName: "edit",
                isVisible: iPermission >= 3,
                disabled: iPermission < 3,
                fn: () => this.onEdit(e),
            },
            {
                iconName: "view",
                isVisible: iPermission === 1,
                disabled: iPermission < 1,
                fn: () => this.onView(e),
            },
            {
                iconName: "delete",
                isVisible: true,
                disabled: iPermission < 4,
                fn: () => this.onDelete(e),
            },
        ].filter((item) => item.isVisible);
        return (
            <GridActionBar>
                {arrIcon.map(({ iconName, disabled, fn }, i) => (
                    <IconButton
                        key={i}
                        disabled={disabled}
                        aria-label={iconName}
                        style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                        onClick={fn}
                    >
                        <Icons name={iconName} />
                    </IconButton>
                ))}
            </GridActionBar>
        );
    };

    render() {
        const { dataGrid } = this.props;
        const { selectedRowKeys, gridLoading, showW71F1001, rowData, mode, iPermission, isCheckDisabled } = this.state;
        const { skip, limit } = this.paramFilters;
        if (!iPermission) return null;
        const dataRow = _.get(dataGrid, "rows", []);
        return (
            <FormGroup>
                <ActionToolbar title={Config.lang("Danh_muc_loai_chi_phi")}>
                    <ButtonGeneral
                        name={Config.lang("Them")}
                        typeButton={"add"}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <Modal
                    open={showW71F1001}
                    maxWidth={"md"}
                    fullWidth={true}
                    title={Config.lang("Lap_loai_chi_phi")}
                    onClose={() => this.onCloseModal(false)}
                >
                    <W71F1001
                        iPermission={iPermission}
                        saveContinue={() => {
                            this.isSaveContinue = true;
                        }}
                        mode={mode}
                        data={rowData}
                        onClose={this.onCloseModal}
                    />
                </Modal>
                <div className={"hidden"}>
                    <Filter onTextChanged={this.onSearch} placeholder={Config.lang("Noi_dung_can_tim_kiem")} />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCheckDisabled}
                            onChange={() => {
                                this.setState({ isCheckDisabled: !isCheckDisabled }, () => this.loadGrid());
                            }}
                            color="primary"
                        />
                    }
                    label={Config.lang("Hien_thi_khong_su_dung")}
                />
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="gird-w29f2005">
                            <GridContainer
                                itemPerPage={limit}
                                skipPerPage={skip}
                                loading={gridLoading}
                                selectedRowKey={selectedRowKeys}
                                style={{ border: "none" }}
                                dataSource={dataRow}
                                keyExpr={"RecCostID"}
                                showBorders={false}
                                typePaging={"remote"}
                                onRowDblClick={this.onView}
                                isOrderAPI={true}
                                totalItems={_.get(dataGrid, "total", 0)}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column
                                    caption={Config.lang("Hanh_dong")}
                                    fixed={true}
                                    alignment="right"
                                    allowEditing={false}
                                    visible={Config.isMobile}
                                    cellRender={this.renderButtonAction}
                                    fixedPosition={"right"}
                                />
                                <Column dataField={"RecCostName"} caption={Config.lang("Ten_loai_chi_phi")} />
                                <Column dataField={"AbsentTypeDateNameU"} caption={Config.lang("Khoan_dieu_chinh_thu_nhap")} />
                                <Column
                                    dataField={"Note"}
                                    cellRender={(e) => {
                                        const { data } = e.row;
                                        return <div style={{ whiteSpace: "pre-wrap" }}>{_.get(data, "Note", "")}</div>;
                                    }}
                                    caption={Config.lang("Ghi_chu")}
                                />
                                <Column dataType="boolean" dataField={"Disabled"} caption={Config.lang("Khong_su_dung")} />
                                <Column caption={" "} width={60} />
                                <Column
                                    fixed={true}
                                    alignment="right"
                                    allowEditing={false}
                                    visible={!Config.isMobile}
                                    cellRender={this.renderButtonAction}
                                    fixedPosition={"right"}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </FormGroup>
        );
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadGrid();
    };

    componentWillUnmount = () => {
        if (this.timer) clearTimeout(this.timer);
    };
}
export default compose(
    connect(
        (state) => ({
            dataGrid: state.W77F1000.dataGrid,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w77F1000Actions: bindActionCreators(W77F1000Actions, dispatch),
        }),
    ),
    withStyles(styles),
)(W77F1000);
