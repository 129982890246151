import React, { Component } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import Modal from "../../../common/modal/modal";
import { Col, Row, FormGroup } from "react-bootstrap";
import Config from "../../../../config";
import { Combo } from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import { withStyles, FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import * as W77F1001Actions from "../../../../redux/W77/W77F1001/W77F1001_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from "lodash";
import Api from "../../../../services/api";

const styles = (theme) => ({
    modalActions: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
});

class W77F1001 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cboLoading: false,
            loadingForm: false,
            isCheckDisabled: false,
            dataForm: {
                Note: "",
                RecCostName: "",
                AbsentTypeDateID: "",
            },
        };
    }

    loadForm = () => {
        const rowData = _.get(this.props, "data", []);
        const RecCostID = _.get(rowData, "RecCostID", "");
        this.setState({ loadingForm: true });
        this.props.w77f1001Actions.loadForm({ RecCostID }, (err, data) => {
            this.setState({ loadingForm: false });
            if (err) {
                const message = _.get(err, "message", "");
                return Config.popup.show("INFO", message || Config.lang("DHR_Loi_chua_xac_dinh"));
            }
            this.setState({
                dataForm: {
                    Note: _.get(data, "Note", _.get(rowData, "Note", "")),
                    RecCostName: _.get(data, "RecCostName", _.get(rowData, "RecCostName", "")),
                    AbsentTypeDateID: _.get(data, "AbsentTypeDateID", _.get(rowData, "AbsentTypeDateID", "")),
                },
                isCheckDisabled: _.get(data, "Disabled", _.get(rowData, "Disabled", false)),
            });
        });
    };

    loadCboAbsentTypeData = () => {
        this.setState({ cboLoading: true });
        this.props.w77f1001Actions.loadCboAbsentType((err) => {
            this.setState({ cboLoading: false });
            if (err) {
                const message = _.get(err, "message", "");
                return Config.popup.show("INFO", message || Config.lang("DHR_Loi_chua_xac_dinh"));
            }
        });
    };

    handleChange = (key, e) => {
        const { dataForm } = this.state;
        const value = _.get(e, "target.value", _.get(e, "value", ""));
        this.setState(
            {
                dataForm: {
                    ...dataForm,
                    [key]: value,
                },
            },
            () => {
                this.props.saveContinue();
            },
        );
    };

    onChangeMode = () => {
        this.setState(
            {
                isCheckDisabled: false,
                dataForm: {
                    Note: "",
                    RecCostName: "",
                    AbsentTypeDateID: "",
                },
            },
            () => {
                this.onStore(true);
            },
        );
    };

    onStore = async (isChangeMode = false) => {
        const { mode, data } = this.props;
        const { dataForm, isCheckDisabled } = this.state;
        const RecCostID = mode === "edit" ? _.get(data, "RecCostID", "") : "";
        const params = {
            ...dataForm,
            Disabled: Number(isCheckDisabled),
            RecCostID,
        };
        this.setState({ loadingForm: true, cboLoading: true });
        const res = await Api.put(`/w77f1001/${mode}`, params);
        this.setState({ loadingForm: false, cboLoading: false });
        const messageErr = _.get(res, "message", "") || _.get(res, "data.Message", "");
        const status = _.get(res, "data.Status", 1);
        if (messageErr || status) return Config.popup.show("INFO", messageErr);
        if (!status) {
            !isChangeMode && this.props.onClose(true);
            return Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 3000);
        }
    };

    render() {
        const { mode, getCboAbsentTypeData, classes, iPermission } = this.props;
        const { dataForm, loadingForm, cboLoading, isCheckDisabled } = this.state;
        const allowSave = iPermission > 2 && dataForm.RecCostName && (mode === "edit" || mode === "add" || loadingForm || cboLoading);
        return (
            <>
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    inputProps={{
                                        maxLength: 499,
                                    }}
                                    label={Config.lang("DHR_Ten_loai_chi_phi")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.RecCostName}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required={true}
                                    disabled={loadingForm}
                                    onChange={(e) => this.handleChange("RecCostName", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Combo
                                    disabled={cboLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={Config.lang("DHR_Khoan_dieu_chinh_thu_nhap")}
                                    margin={"normal"}
                                    stylingMode={"underlined"}
                                    dataSource={getCboAbsentTypeData}
                                    displayExpr={"AbsentTypeDateNameU"}
                                    valueExpr={"AbsentTypeDateID"}
                                    showClearButton={true}
                                    value={dataForm.AbsentTypeDateID}
                                    onValueChanged={(e) => this.handleChange("AbsentTypeDateID", e)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    inputProps={{
                                        maxLength: 499,
                                    }}
                                    multiline={true}
                                    label={Config.lang("DHR_Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.Note}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={loadingForm}
                                    onChange={(e) => this.handleChange("Note", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions className={classes.modalActions}>
                    <FormControlLabel
                        disabled={loadingForm}
                        control={
                            <Checkbox
                                checked={isCheckDisabled}
                                onChange={() => this.setState({ isCheckDisabled: !isCheckDisabled })}
                                color="primary"
                            />
                        }
                        label={Config.lang("DHR_Khong_su_dung")}
                    />
                    <div className={classes.modalActionButton}>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={!Boolean(allowSave)}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            onClick={this.onStore}
                        />
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu_&_nhap_tiep")}
                            typeButton={"add"}
                            disabled={!Boolean(allowSave)}
                            style={{ textTransform: "uppercase", marginLeft: 5 }}
                            size={"large"}
                            onClick={this.onChangeMode}
                        />
                    </div>
                </Modal.Actions>
            </>
        );
    }

    componentDidMount = () => {
        const { mode } = this.props;
        this.loadCboAbsentTypeData();
        if (mode === "edit" || mode === "view") {
            this.loadForm();
        }
    };
}

export default compose(
    connect(
        (state) => ({
            getForm: state.W77F1001.getForm,
            getCboAbsentTypeData: state.W77F1001.getCboAbsentTypeData,
        }),
        (dispatch) => ({
            w77f1001Actions: bindActionCreators(W77F1001Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles, { withTheme: true }),
)(W77F1001);
