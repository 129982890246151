import React, {PureComponent} from 'react';
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import {Column, RequiredRule, Summary, TotalItem} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import UndoIcon from '@material-ui/icons/Undo';
import IconButton from "@material-ui/core/IconButton";
import {Checkbox as CheckboxM} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Icons from "../../../common/icons";
import { Lookup } from "devextreme-react";
import NumberFormat from 'react-number-format';

const styles = {
    checkbox: {
        padding: '0 4px'
    },
    editorGrid: {
        "&:after": {
            content: "none"
        },
        "& input": {
            height: "40px !important",
            minHeight: "40px !important",
        }
    },
};

class W77F1011TableInfo extends PureComponent {
    constructor(props) {
        super(props);
        this.dataGrid = null;
        this.addGrid = false;
        this.isUpdating = 0;
        this.keyRowInserted = "";
        // this.keyInserting = "";
    }

    renderButtons = (e) => {
        return (
           <div className={"grid-action-hover hidden"}>
               {
                   !e.row.isEditing || !this.addGrid ? (
                       <IconButton
                           aria-label="view"
                           size={"small"}
                           style={{ padding: 0, marginRight: 4 }}
                           onClick={() => e.component.deleteRow(e.rowIndex)}
                       >
                           <Icons name={"delete"} />
                       </IconButton>
                   ) : (
                       <>
                           <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                       size={"small"}
                                       onClick={() => e.component.saveEditData()}
                           >
                               <Icons name={"save_filled"} type={"inline"}/>
                           </IconButton>
                           <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                       size={"small"}
                                       onClick={() => e.component.cancelEditData()}
                           >
                               <UndoIcon fontSize="small"/>
                           </IconButton>
                       </>
                   )
               }
           </div>
        );
    };

    renderDisabled = (e) => {
        const {classes} = this.props;
        return (
            <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"/>
        );
    };

    onConvertibleValueCurrentcies = (e) => {
        if (!e) return false;
        const {getConvertibleValueCurrentcies} = this.props;
        const data = e.newData ? e.newData : null;
        const oldData = e.oldData ? e.oldData : null;
        const rowIndex = this.addGrid ? 0 : e.component && e.component.getRowIndexByKey(e.key);
        let key = this.fieldUpdating || "";
        key = key === "CurrencyName" ? "CurrencyID" : key;
        const fieldValid = ["AmountVND", "AmountUSD", "ForeignCurrency", "CurrencyID"];
        if (key && fieldValid.indexOf(key) > -1 && getConvertibleValueCurrentcies) {
            let params = {};
            switch (key) {
                case "AmountVND":
                    params = {
                        Mode: 0,
                        Amount:  data.AmountVND,
                        CurrencyID: ""
                    };
                    this.getApi(key, params, rowIndex);
                    break;
                case "AmountUSD":
                    params = {
                        Mode: 1,
                        Amount: data.AmountUSD,
                        CurrencyID: ""
                    };
                    this.getApi(key, params, rowIndex);
                    break;
                case "CurrencyID":
                    let Amount = "";
                    if (this.addGrid && this.dataGrid) {
                        Amount = this.dataGrid.instance.cellValue(0, "ForeignCurrency");
                    } else if (oldData) {
                        Amount = oldData.ForeignCurrency ?oldData.ForeignCurrency : ""
                    }
                    params = {
                        Mode: 2,
                        Amount:  Amount,
                        CurrencyID: data.CurrencyID
                    };
                    if(params && params.Amount && params.CurrencyID) {
                        this.getApi(key, params, rowIndex);
                    }
                    break;
                case "ForeignCurrency":
                    let CurrencyID = "";
                    if (this.addGrid && this.dataGrid) {
                        CurrencyID = this.dataGrid.instance.cellValue(0, "CurrencyID");
                    } else if (oldData) {
                        CurrencyID = oldData.CurrencyID ? oldData.CurrencyID : ""
                    }
                    params = {
                        Mode: 2,
                        Amount:  data.ForeignCurrency,
                        CurrencyID: CurrencyID
                    };
                    if(params && params.Amount && params.CurrencyID) {
                        this.getApi(key, params, rowIndex);
                    }
                    break;
                default:
                    break;
            }
        }
    };

        getApi =  (key, params,rowIndex) => {
            const {getConvertibleValueCurrentcies} = this.props;
            this.fieldUpdating = "";
            getConvertibleValueCurrentcies && getConvertibleValueCurrentcies(params, (error, _data) => {
                // this.isAdding = false;
                if (this.dataGrid && _data) {
                    // let rowIndex = 0;
                    // if (!this.addGrid) {
                    //     rowIndex = this.dataGrid.instance.getRowIndexByKey(this.keyInserting);
                    // }
                    if (key === "CurrencyID" || key === "ForeignCurrency") {
                        this.dataGrid.instance.cellValue(rowIndex, "AmountVND", _data.AmountVND);
                        this.dataGrid.instance.cellValue(rowIndex, "AmountUSD", _data.AmountUSD);
                    } else if (key === "AmountVND") {
                        this.dataGrid.instance.cellValue(rowIndex, "AmountUSD", _data.AmountUSD);
                    } else if (key === "AmountUSD") {
                        this.dataGrid.instance.cellValue(rowIndex, "AmountVND", _data.AmountVND);
                    }
                    this.dataGrid.instance.editCell(rowIndex,"RecCostID");
                    if (this.isAdding) {
                        this.isAdding = false;
                        if (this.isInserting) {
                            this.isInserting = false;
                            this.dataGrid.instance.saveEditData();
                        }
                    }
                }
            });
    };

    onChange = (e, field) => {
        if (!(e && field)) return false;
        if (this.addGrid) {
            const value =  e.event ? e.event.target.value : e;
            const param = {
                newData: {[field]: value}
            };
            this.isAdding = true;
            this.onConvertibleValueCurrentcies(param);

        }
    };

    onCellClick = (e) => {
        this.selectedRowIndx = e.rowIndex;
    };

    formatNumber = (e) => {
        if(!e) return false;
        return <NumberFormat value={e.value} displayType={'text'} thousandSeparator={true} />
    };

    calculateCompanyPay = () => {
        const {calculateCompanyPay} = this.props;
        if (calculateCompanyPay) calculateCompanyPay();
    };

    getDataTotal = () => {
        this.calculateCompanyPay();
    };

    render() {
        const {gridRef, classes, getFieldHide, IsDomestic, getCboCostTypes, dataCostWorkPlans, getLoadCurrency} = this.props;
        const {IsUseForeignCurrency} = getFieldHide;
        return (
            <div className={"mgb25"} style={{width:'100%'}}>
                <GridContainer
                    reference={ref => {
                        gridRef && gridRef(ref);
                        this.dataGrid = ref;
                    }}
                    columnAutoWidth={true}
                    dataSource={dataCostWorkPlans}
                    keyExpr={"RecCostID"}
                    elementAttr={{
                        style: "min-height: 200px"
                    }}
                    style={{width: "100%"}}
                    editing={{
                        mode: 'cell',
                        refreshMode: "reshape",
                        allowUpdating: true,
                        texts: {
                            confirmDeleteMessage: ""
                        }
                    }}
                    // filterRow={{
                    //     visible: true,
                    //     showOperationChooser: false,
                    // }}
                    pagerFullScreen={false}
                    hoverStateEnabled={true}
                    typeShort={true}
                    allowColumnResizing={true}
                    onInitNewRow={() => {
                        this.addGrid = true;
                    }}
                    onCellClick={this.onCellClick}
                    onRowInserted={() => {
                        this.addGrid = false;
                        this.getDataTotal();
                    }}
                    onRowInserting={e => {
                        if (this.isAdding) {
                            e.cancel = true;
                            this.isInserting = true;
                        }
                    }}
                    onEditingStart={(e) => {
                        // const {data} = e;
                        // this.keyInserting = data["__KEY__"];
                        this.fieldUpdating = e.column.dataField;
                    }}
                    onRowUpdating={(e) => {
                        this.isAdding = true;
                        this.isInserting = true;
                        this.onConvertibleValueCurrentcies(e)
                    }}
                    onRowUpdated={this.getDataTotal}
                >
                    <Column
                        caption={Config.lang("Hanh_dong")}
                        allowEditing={false}
                        width={110}
                        alignment={"center"}
                        visible={Config.isMobile}
                        cellRender={this.renderButtons}
                    />
                    <Column  caption={Config.lang("Khoan_chi")}
                             dataField={'RecCostID'}
                             width={250}
                             lookup={{
                                dataSource: getCboCostTypes,
                                valueExpr: "RecCostID",
                                displayExpr: "RecCostName",
                             }}
                             editorOptions={{ placeholder: Config.lang("Khoan_chi") + "...",
                    }}

                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        dataField="AmountVND"
                        caption={Config.lang("Thanh_tien(VND)")}
                        dataType="number"
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        width={250}
                        cellRender={this.formatNumber}
                        editorOptions={{
                            onChange: (e) => {
                                this.addGrid && this.onChange(e, "AmountVND");
                            }
                        }}
                    />
                    <Column
                        caption={Config.lang("Thanh_tien(USD)")}
                        dataField={'AmountUSD'}
                        dataType="number"
                        format={{
                            type: 'decimal',
                            min: 0
                        }}
                        width={250}
                        editorOptions={{
                            onChange: (e) => {
                                this.addGrid && this.onChange(e, "AmountUSD");
                            }
                        }}
                    />

                    {Number(IsUseForeignCurrency) === 1 && Number(IsDomestic) === 1 &&
                    <Column
                        dataField={'CurrencyID'}
                        visible={false}
                    />
                    }

                    {Number(IsUseForeignCurrency) === 1 && Number(IsDomestic) === 1 &&
                        <Column
                            caption={Config.lang("Loai_tien")}
                            dataField={'CurrencyName'}
                            width={200}
                            editCellRender={(e) => {
                                const {data} = e;
                                return <Lookup
                                        dataSource={getLoadCurrency}
                                        valueExpr={"CurrencyID"}
                                        displayExpr={"CurrencyName"}
                                        value={data.CurrencyID}
                                        elementAttr={{
                                            class: classes.editorGrid,
                                            style: "margin: -5px 0"
                                        }}
                                        activeStateEnabled={false}
                                        focusStateEnabled={false}
                                        hoverStateEnabled={false}
                                        placeholder={Config.lang("Loai_tien") + "..."}
                                        onValueChanged={(lk) => {
                                            const currency = getLoadCurrency.find(c => c.CurrencyID === lk.value);
                                            if (currency) {
                                                e.setValue(currency.CurrencyName);
                                                e.component.cellValue(e.rowIndex, "CurrencyID", currency.CurrencyID);
                                                e.component.cellValue(e.rowIndex, "ExchangeRate", currency.ExchangeRate);
                                                if (this.addGrid) this.onChange(currency.CurrencyID,"CurrencyID");
                                            }
                                        }}
                                    />;
                            }}
                        />
                    }

                    {Number(IsUseForeignCurrency) === 1 && Number(IsDomestic) === 1 &&
                        <Column
                            caption={Config.lang("Ty_gia")}
                            dataField={'ExchangeRate'}
                            width={200}
                            allowEditing={false}
                        />
                    }

                    {Number(IsUseForeignCurrency) === 1 && Number(IsDomestic) === 1 &&
                            <Column
                                caption={Config.lang("Ngoai_te")}
                                dataField={'ForeignCurrency'}
                                width={200}
                                editorOptions={{
                                    onChange: (e) => {
                                        this.addGrid && this.onChange(e, "ForeignCurrency");
                                    }
                                }}
                            />
                    }

                    <Column
                        caption={Config.lang("Cong_ty_thanh_toan")}
                        dataField={'IsCompanyPay'}
                        width={150}
                        alignment={'center'}
                        cellRender={this.renderDisabled}
                        editCellRender={(e) => {
                            return <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"
                                              onChange={(chk) => {
                                                  e.component.cellValue(e.rowIndex, "IsCompanyPay", chk.target.checked ? 1 : 0);
                                              }}
                            />
                        }}
                    />
                    <Column
                        caption={Config.lang("Ghi_chu")}
                        dataField={'Note'}
                    />
                    <Column
                            allowEditing={false}
                            visible={!Config.isMobile}
                            fixed={true}
                            fixedPosition={"right"}
                            cellRender={this.renderButtons}
                    />
                    <Summary recalculateWhileEditing={true}>
                        <TotalItem
                            column="RecCostID"
                            displayFormat={Config.lang("Tong")}
                        />
                        <TotalItem
                            column="AmountVND"
                            summaryType="sum"
                            valueFormat={"fixedPoint"}
                            displayFormat={'{0}'}
                        />
                        <TotalItem
                            column="AmountUSD"
                            summaryType="sum"
                            displayFormat={'{0}'}
                        />
                        <TotalItem
                            column="ForeignCurrency"
                            summaryType="sum"
                            displayFormat={'{0}'}
                        />
                    </Summary>
                </GridContainer>
            </div>
        );
    }
}
W77F1011TableInfo.propTypes  = {
    gridRef: PropTypes.func,
    IsDomestic: PropTypes.number,
    dataCostWorkPlans: PropTypes.array,
    getCboCostTypes: PropTypes.array,
    getConvertibleValueCurrentcies: PropTypes.func,
    getFieldHide: PropTypes.any,
    getLoadCurrency: PropTypes.array,
};


export default withStyles(styles)(W77F1011TableInfo) ;