import React, {Component} from 'react';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import {makeStyles} from "diginet-core-ui/theme";
import {bindActionCreators, compose} from "redux";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Api from "../../../../services/api";
import _ from "lodash";
import * as generalActions from "../../../../redux/general/general_actions";
import Filter from "../../../filter/filter";
import {connect} from "react-redux";
import UserImage from "../../../common/user/user-image";
import W75F8002 from "../W75F8002/W75F8002";
import W75F8001 from "../W75F8001/W75F8001";
import W75F8003 from "../W75F8003/W75F8003";
import {
    ButtonIcon,
    Row,
    Col,
    TabContainer,
    TabHeader,
    TabItem,
    TextInput,
    DatePicker,
    Dropdown,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Typography,
} from "diginet-core-ui/components";
import DOMPurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        border: `0 0.5px solid ${theme.colors.line.normal}`
    },
    tabs:{
        minWidth:160
    },
    tabGridRight: {
        width: "90%",
        borderLeft: `0.5px solid ${theme.colors.line.normal}`,
    },
    approverCol: {
        display: "flex",
        flexDirection: "column",
        maxHeight: 105,
        overflowX: "scroll"
    },
}))

class W75F8000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            module: null,
            titleModal: "",
            FormID: '',
            dataProps: {},
            GroupID: 0,
            StatusID: 0,
            transIDSelected: "",
            DateFrom: "",
            DateTo: "",
            ObjectName: "",
            iPermission: 0,
            dataGrid: {rows: [], total: 0},
            selectedRowsData: [],
            selectedRowKeys: [],
            dataCboEmployees: {rows: [], total: 0},
            dataCboTransaction: [],
            isEmployeeLoading: false,
            isCboTransactionLoading: false,
            isGridLoading: false,
            showW75F8001: false,
            showW75F8002: false,
            showW75F8003: false,
            dataW75F8003: {},
            showPopupByFormID: false,
            totalDataOT1stStatusTab: 0,
        };
        this.params = {
            FormID: "W75F8000",
            Language: Config.language || '84'
        };
        this.filter = {
            Employee: null,
            TransactionID: "ALL",
            skip: 0,
            limit: 10,
        };
        this.filterEmployees = [
            {
                ...this.params,
                search: "",
                timer: null,
                skip: 0,
                limit: 50
            },
            {
                FormID: "W75F8001",
                Language: Config.language || '84',
                search: "",
                skip: 0,
                limit: 10
            }
        ];
        this.preSelectedRowKeys = React.createRef();
        this.preDataRowsSelected = React.createRef();
        this.onSearchEmployeeGridPopup = _.debounce(this.onSearchEmployeeGrid, 1000);
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W75F8000", (isPer) => {
            this.setState({iPermission: isPer});
        }, true);
    };

    resetParamOnChangingTab = () => {
        this.filter = {
            Employee: null,
            TransactionID: "ALL",
            skip: 0,
            limit: 10,
        };
        this.filterEmployees[0] = {
            ...this.params,
            search: "",
            timer: null,
            skip: 0,
            limit: 50
        };
    }

    setTitleModalByFormID = (screenName) => {
        let title = "";
        switch (screenName) {
            case "W09F2011":
                title = Config.lang("Dang_ky_nghi_viec");
                break;
            case "W75F2000":
                title = Config.lang("Lap_nghi_phep")
                break;
            case "W09F2060":
                title = Config.lang("Lap_de_xuat_khen_thuong")
                break;
            case "W09F2070":
                title = Config.lang("Lap_de_xuat_ky_luat")
                break;
            case "W09F2080":
                title = Config.lang("Lap_de_xuat_dieu_chuyen_lao_dong")
                break;
            case "W09F2021":
                title = Config.lang("Cap_nhat_sau_thai_san")
                break;
            case "W29F2012":
                title = Config.lang("Dang_ky_tang_ca")
                break;
            case "W77F1013":
                title = Config.lang("")
                break;
            case "W75F2010":
                title = Config.lang("Lap_de_xuat_dieu_chinh_thu_nhap")
                break;
            case "W09F2150":
                title = Config.lang("Lap_de_xuat_thay_doi_chuc_danh")
                break;
            case "W09F2032":
                title = Config.lang("Dieu_chinh_luong")
                break;
            case "W25F2002":
                title = Config.lang("")
                break;
            case "W29F2031":
                title = Config.lang("Bo_sung_gio_vao_ra")
                break;
            case "W29F2081":
                title = Config.lang("Dang_ky_di_tre_ve_som")
                break;
            default:
                break
        }
        return title
    }

    onDbCellClick = async (e) => {
        const formID = _.get(e, "data.FormID", "");
        const TransID = _.get(e, "data.TransID", "");
        const AppStatusID = _.get(e, "data.AppStatusID", "");
        const ObjectID = _.get(e, "data.ObjectID", "");
        let module = {default: null};
        let popupProps = {
            TransID,
            LeaveTransID: TransID,
            VoucherID: TransID,
            ProTransID: TransID,
            MaternityTransID: TransID,
            AppStatusID,
        }
        if (_.isEmpty(formID)) return false;
        switch (formID) {
            // suffix Popup
            case "W09F2060":
            case "W09F2150":
            case "W09F2070":
            case "W09F2080":
            case "W09F2032":
            case "W75F2000":
            case "W09F2021":
                module = await import(`../../../${formID.slice(0,2)}X/${formID.slice(0,3)}/${formID}/${formID}Popup`);
                break;
            // none suffix Popup
            case "W29F2012":
            case "W25F2002":
            case "W29F2045":
            case "W29F2060":
            case "W25F2040":
            case "W29F2031":
            case "W29F2081":
            case "W77F1013":
            case "W09F2011":
            case "W75F2010":
            case "W52F1001":
                module = await import(`../../../${formID.slice(0,2)}X/${formID.slice(0,3)}/${formID}/${formID}`);
                break;
            default:
                break;
        }
        this.setState({
            titleModal: this.setTitleModalByFormID(formID),
            showPopupByFormID: true,
            module: module.default,
            FormID: formID ?? '',
            TransID,
            dataProps: popupProps,
            ObjectID
        })
    }

    getCurrentRowsSelected = (type, data = [], dataSelected = []) =>
        _.size(dataSelected) > 0 ?
            _.reduce(data, (acc, item) => {
                if (_.includes(dataSelected, _.get(item, "TransID", ""))) {
                    acc = [...acc, type === "key" ? item.TransID : item]
                }
                return acc;
            }, []) : [];

    loadGrid = async () => {
        const {GroupID, StatusID, DateFrom, DateTo, ObjectName} = this.state;
        const {TransactionID, Employee, skip, limit} = this.filter;
        let statusIDOfTab = StatusID === 2 ? 4 : StatusID;
        const params = {
            ...this.params,
            GroupID,
            Object: ObjectName,
            StatusID: statusIDOfTab,
            DateFrom,
            DateTo,
            TransactionID: TransactionID || "ALL",
            CreateUserID: Employee || "",
            skip,
            limit
        };

        this.setState({isGridLoading: true});
        const res = await Api.put("/w75f8000/load-form", params);
        this.setState({isGridLoading: false});
        const resData = _.get(res, "data", []);
        const resDataErrMessage = _.get(resData, "Message", "");
        const resErrMessage = _.get(res, "message", "");
        if (resErrMessage || resDataErrMessage) {
            Config.popup.show("ERROR", resErrMessage || resDataErrMessage);
            return false;
        }
        const resDataRows = _.get(resData, "rows", resData) || [];
        const resDataTotal = _.get(resData, "total", resData.length);
        const resDataRowsWithIsUsed = _.chain(resDataRows).filter('IsUsed');

        const selectedRowKeysCurrent = _.uniq([
            ...(this.preSelectedRowKeys.current ?? []),
            ...resDataRowsWithIsUsed.map('TransID').value()
        ]);

        if (StatusID === 0) this.setState({totalDataOT1stStatusTab: resDataTotal});
        const rows = _.map(resDataRows, item => ({
            ...item,
            ApproverName: {dataApprover: _.get(item, "ApproverName", "").split(","), limit: 3}
        }));
        this.setState({
            dataGrid: {
                rows,
                total: resDataTotal
            },
            selectedRowsData: this.getCurrentRowsSelected("data", rows, selectedRowKeysCurrent),
            selectedRowKeys: this.getCurrentRowsSelected("key", rows, selectedRowKeysCurrent),
        });
    }

    loadCboEmployees = (isReset = false, type = 0) => {
        const {dataCboEmployees} = this.state;
        const params = {...this.filterEmployees[type], Type: "EmployeeID"};
        this.setState({isEmployeeLoading: true});
        this.props.generalActions.getCboEmployees(params, (err, data) => {
            this.setState({isEmployeeLoading: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (!type && data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0))
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total
                    }
                })
            }
        });
    };

    loadCboTransaction = async () => {
        const res = await Api.put("/w75f8000/load-transaction-new", this.params);
        const resData = _.get(res, "data", []);
        const resDataErrMessage = _.get(resData, "Message", "");
        const resErrMessage = _.get(res, "message", "");
        if (resErrMessage || resDataErrMessage) {
            Config.popup.show("ERROR", resErrMessage);
            return false;
        }
        this.setState({
            dataCboTransaction: resData
        });
    }

    onCloseModalByFormID = () => {
        this.setState({
            showPopupByFormID: false
        })
    }

    onChangeTabGroup = (_, value) => {
        const {StatusID} = this.state;
        this.resetParamOnChangingTab();
        this.setState({GroupID: value}, () => {
            this.loadGrid();
            if (StatusID !== 0) this.setState({totalDataOT1stStatusTab: "..."})
        });
    };

    onChangeTabStatus = (_, value) => {
        this.resetParamOnChangingTab();
        this.setState({StatusID: value}, () => {
            this.loadGrid();
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid(true);
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid(true);

    };

    onChangePageEmployeeGrid = (paramFilter) => {
        this.filterEmployees[1] = {...this.filterEmployees[1], ...paramFilter}
        this.loadCboEmployees(_, 1);
    }

    onSearchEmployeeGrid = (strSearch) => {
        this.filterEmployees[1].search = strSearch;
        this.loadCboEmployees(_, 1)
    }

    onFilter = () => {
        this.filter.skip = 0;
        this.filter.limit = 10;
        this.loadCboTransaction();
        this.loadCboEmployees(true)
    }

    onFilterChange = (key, e) => {
        const value = e?.target?.value ?? e?.value ?? "";
        this.setState({
            [key]: value
        });
        this.filter[key] = value;
    }

    renderFilter = () => {
        const {TransactionID, Employee} = this.filter;
        const {
            isGridLoading,
            dataCboTransaction,
            dataCboEmployees,
            isEmployeeLoading,
            isCboTransactionLoading,
            DateFrom,
            DateTo,
            ObjectName
        } = this.state;
        return (
            <Filter
                isUseDDCore
                disabled={isGridLoading}
                onOpened={this.onFilter}
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={
                    () => {
                        return (
                            <Row>
                                <Col xs={12} sm={2}>
                                    <Typography type="h4">
                                        {Config.lang("Ngay_tao")}
                                    </Typography>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <DatePicker
                                        max={DateTo}
                                        onChange={e => this.onFilterChange("DateFrom", e)}
                                        clearAble
                                        placeholder={"DD/MM/YYYY"}
                                        value={DateFrom}
                                    />
                                </Col>
                                <Col xs={12} sm={5}>
                                    <DatePicker
                                        min={DateFrom}
                                        onChange={e => this.onFilterChange("DateTo", e)}
                                        clearAble
                                        placeholder={"DD/MM/YYYY"}
                                        value={DateTo}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        label={Config.lang("Quy_trinh")}
                                        dataSource={dataCboTransaction}
                                        displayExpr={"TransactionName"}
                                        valueExpr={"TransactionID"}
                                        onChange={e => this.onFilterChange("TransactionID", e)}
                                        clearAble
                                        value={TransactionID}
                                        loading={isCboTransactionLoading}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        dataSource={dataCboEmployees.rows}
                                        skip={this.filterEmployees[0].skip}
                                        limit={this.filterEmployees[0].limit}
                                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                                        valueExpr={"EmployeeID"}
                                        value={Employee}
                                        viewType={"outlined"}
                                        loading={isEmployeeLoading}
                                        label={Config.lang("Nguoi_tao")}
                                        clearAble
                                        onChange={e => this.onFilterChange("Employee", e)}
                                        onInput={e => {
                                            this.filterEmployees[0].search = e.target.value;
                                            this.filterEmployees[0].skip = 0;
                                            this.loadCboEmployees(true);
                                        }}
                                        onLoadMore={e => {
                                            this.filterEmployees[0].skip = e.skip;
                                            this.filterEmployees[0].limit = e.limit;
                                            this.loadCboEmployees();
                                        }}
                                        searchDelayTime
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <TextInput
                                        value={ObjectName}
                                        onChange={e => this.onFilterChange("ObjectName", e)}
                                        label={Config.lang("Doi_tuong")}
                                        viewType="outlined"
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} className="display_row valign-middle">
                                    <Button
                                        label={Config.lang("Tim_kiem")}
                                        startIcon={"search"}
                                        size={"medium"}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        onClick={() => this.loadGrid()}
                                    />
                                </Col>
                            </Row>
                        );
                    }
                }
            />
        )
    };

    renderEmpAvatar = (e) => {
        const {data} = e.row;
        return (
            <div className="display_row align-center">
                <UserImage valueExpr={'UserID'} keyExpr={'CreateUserID'} width={38} height={38} data={data}/>
            </div>
        );
    };

    renderApprovers = (e) => {
        const classes = useStyles();
        const {data} = e.row;
        const dataApprover = _.get(data, "ApproverName.dataApprover", [])
        return (
            <div className={classes.approverCol}>
                <span>{_.map(dataApprover, (approverName, i) => <React.Fragment
                    key={i}>{approverName}<br/></React.Fragment>)}</span>
            </div>
        )
    };

    onOpenPopup = (state, option = {}, cb = () => {
    }) => {
        this.setState({
            [state]: true,
            ...option,
        }, cb)
    }

    onClosePopup = (state, isReload = false) => {
        if (isReload) {
            this.setState({
                selectedRowKeys: [],
                selectedRowsData: []
            }, () => {
                this.loadGrid();
                this.preSelectedRowKeys.current = [];
                this.preDataRowsSelected.current = []
            })
        }
        this.setState({
            [state]: false
        })
    }

    onSelectionChanged = async (e) => {
        const {dataGrid} = this.state;
        const {selectedRowKeys, selectedRowsData} = e;
        let allSelectedRowKeys = _.uniq([...(this.preSelectedRowKeys.current ?? []), ...selectedRowKeys]);
        let allSelectedDataRows = _.uniqBy([...(this.preDataRowsSelected.current ?? []), ...selectedRowsData], "TransID");
        const currentRowSelected = this.getCurrentRowsSelected("key", dataGrid.rows, allSelectedRowKeys);
        const deselectedRowKeys = _.filter(currentRowSelected, rowKey => !_.includes(selectedRowKeys, rowKey));
        await (() => {
            this.preSelectedRowKeys.current = _.filter(allSelectedRowKeys, rowKey => !_.includes(deselectedRowKeys, rowKey))
            this.preDataRowsSelected.current = _.filter(allSelectedDataRows, item => !_.includes(deselectedRowKeys, item.TransID));
        })();
        this.setState({selectedRowKeys, selectedRowsData});
    }

    renderAction = (e) => {
        const {iPermission} = this.state;
        const {data} = e.row;
        const buttonList = [
            {
                name: "History",
                onClick: () => this.onOpenPopup("showW75F8002", {transIDSelected: _.get(data, "TransID", "showW75F8002")}),
                disabled: false
            },
            {
                name: "PersonSync",
                onClick: () => this.setState({dataW75F8003: data, showW75F8003: true}),
                disabled: iPermission < 3
            }
        ]
        return _.map(buttonList, (props, i) => <ButtonIcon key={i} circular viewType={"text"} {...props} />)
    }

    render() {
        const classes = useStyles();
        const {
            titleModal,
            module: Component,
            FormID,
            dataProps,
            ObjectID,
            TransID,
            GroupID,
            StatusID,
            dataGrid,
            iPermission,
            showW75F8002,
            showW75F8001,
            showW75F8003,
            dataW75F8003,
            selectedRowKeys,
            isGridLoading,
            transIDSelected,
            isEmployeeLoading,
            showPopupByFormID,
            totalDataOT1stStatusTab,
        } = this.state;
        const {skip, limit} = this.filter;
        const allSelectedRowKeys = _.uniq([...(this.preSelectedRowKeys.current ?? []), ...this.state.selectedRowKeys]);
        const allSelectedDataRows = _.uniqBy([...(this.preDataRowsSelected.current ?? []), ...this.state.selectedRowsData], "TransID");
        const countNumberAllSelectedRowKeys = _.size(allSelectedRowKeys);
        const hasAVoucherChecked = StatusID === 0 && countNumberAllSelectedRowKeys > 0;
        return (
            <>
                {FormID === "W25F2040" ? (Component && <Component
                    open={showPopupByFormID}
                    mode={1}
                    FormID={"W75F8000"}
                    RecInfoID={''}
                    CandidateID={ObjectID}
                    TransID={TransID}
                    onClose={() => this.onCloseModalByFormID(false)}
                    isShowModal={showPopupByFormID}
                    modeForm={"view"}
                    fromScreen={"W75F8000"}
                    readOnly={true}
                />) : (<Modal 
                    open={showPopupByFormID}
                    zIndex={1019}
                    width={960}
                    onClose={() => this.onCloseModalByFormID(false)}>
                        <ModalHeader>
                            {titleModal}
                        </ModalHeader>
                        {Component && <ModalBody>
                            <Component
                                open={showPopupByFormID}
                                mode={"view"}
                                FormID={FormID ?? ''}
                                data={dataProps}
                                onClose={() => this.onCloseModalByFormID(false)}
                                isShowModal={showPopupByFormID}
                                modeForm="view"
                                disabled={true}
                                dataCboEmployee={{rows: [], total: 0}}
                            />
                        </ModalBody>}
                </Modal>)}
                {showW75F8003 && <W75F8003 iPermission={iPermission} open={showW75F8003} data={dataW75F8003} onClose={this.onClosePopup}/>}
                {showW75F8001 ? <W75F8001
                        loading={isEmployeeLoading}
                        skip={this.filterEmployees[1].skip}
                        limit={this.filterEmployees[1].limit}
                        search={this.filterEmployees[1].search}
                        onSearchEmployee={this.onSearchEmployeeGridPopup}
                        onChangePage={this.onChangePageEmployeeGrid}
                        open={showW75F8001}
                        onClose={this.onClosePopup}
                        voucherSelected={allSelectedDataRows}/>
                    : <W75F8002 transID={transIDSelected} onClose={this.onClosePopup} open={showW75F8002}/>}
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Truy_van_don_tu")}>
                    <Button
                        text={`${Config.lang('Them_nguoi_duyet')} ${!!countNumberAllSelectedRowKeys ? countNumberAllSelectedRowKeys : ""}`}
                        startIcon={'AddFilled'}
                        viewType="filled"
                        size={"medium"}
                        onClick={() => this.onOpenPopup('showW75F8001', {}, () => {
                            this.loadCboEmployees(_, 1);
                        })}
                        disabled={isGridLoading || !hasAVoucherChecked}
                    />
                </ActionToolbar>
                <div className={classes.contentContainer}>
                    <div className="display_row">
                        <div>
                            <TabContainer
                                direction="vertical"
                                tabIndex={GroupID}
                            >
                                <TabHeader>
                                    <TabItem
                                        index={0}
                                        onClick={this.onChangeTabGroup}
                                        label={Config.lang("Tat_ca_don_tu")}
                                        disabled={isGridLoading}
                                    />
                                    <TabItem
                                        index={1}
                                        onClick={this.onChangeTabGroup}
                                        label={Config.lang("Don_tu_cua_toi")}
                                        disabled={isGridLoading}
                                    />
                                    <TabItem
                                        index={2}
                                        onClick={this.onChangeTabGroup}
                                        label={Config.lang("Don_tu_toi_duyet")}
                                        disabled={isGridLoading}
                                    />
                                </TabHeader>
                            </TabContainer>
                        </div>
                        <div className={classes.tabGridRight}>
                            <TabContainer
                                tabIndex={StatusID}
                            >
                                <TabHeader>
                                    <TabItem
                                        className={classes.tabs}
                                        index={0}
                                        onClick={this.onChangeTabStatus}
                                        label={Config.lang("Cho_duyet") + `(${totalDataOT1stStatusTab})`}
                                        disabled={isGridLoading}
                                    />
                                    <TabItem
                                        className={classes.tabs}
                                        index={1}
                                        onClick={this.onChangeTabStatus}
                                        label={Config.lang("Da_duyet")}
                                        disabled={isGridLoading}
                                    />
                                    <TabItem
                                        className={classes.tabs}
                                        index={2}
                                        onClick={this.onChangeTabStatus}
                                        label={Config.lang("Tu_choi")}
                                        disabled={isGridLoading}
                                    />
                                </TabHeader>
                            </TabContainer>
                            <GridContainer
                                hoverStateEnabled={true}
                                style={{border: 'none'}}
                                dataSource={dataGrid.rows}
                                showRowLines={true}
                                keyExpr={'TransID'}
                                showBorders={false}
                                showColumnLines={false}
                                noDataText={Config.lang("No_data")}
                                loading={isGridLoading}
                                height={850}
                                onDbCellClick={this.onDbCellClick}
                                totalItems={dataGrid.total}
                                itemPerPage={limit}
                                skipPerPage={skip}
                                typePaging={"remote"}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                selectedRowKey={selectedRowKeys}
                                selection={{
                                    deferred: false,
                                    allowSelectAll: true,
                                    selectAllMode: "allPages",
                                    mode: StatusID === 0 ? "multiple" : "single",
                                    showCheckBoxesMode: "always"
                                }}
                                onSelectionChanged={this.onSelectionChanged}
                            >
                                <Column alignment={"left"}
                                        caption={Config.lang("Nguoi_tao")}
                                        cellRender={this.renderEmpAvatar}/>
                                <Column alignment={"left"} caption={Config.lang("Ma_doi_tuong")}
                                        dataField={"ObjectID"}/>
                                <Column alignment={"left"} caption={Config.lang("Ten_doi_tuong")}
                                        dataField={"ObjectName"}/>
                                <Column alignment={"left"} caption={Config.lang("Nguoi_duyet")}
                                        cellRender={this.renderApprovers}/>
                                <Column alignment={"center"} caption={Config.lang("Trang_thai")}
                                        dataField={"AppStatusName"}/>
                                <Column alignment={"left"} caption={Config.lang("Phong_ban")}
                                        dataField={"DepartmentName"}/>
                                <Column alignment={"left"} caption={Config.lang("Loai_don")}
                                        dataField={"TransactionName"}/>
                                <Column alignment={"left"} caption={Config.lang("Vi_tri")}
                                        dataField={"DutyName"}/>
                                <Column alignment={"left"} caption={Config.lang("Du_an")}
                                        dataField={"ProjectName"}/>
                                <Column alignment={"center"} caption={Config.lang("Ngay_tao")}
                                        dataField={"CreateDate"} dataType={"date"} format={"dd/MM/yyyy"}/>
                                <Column alignment={"left"} caption={Config.lang("Chi_tiet")}
                                        dataField={"Details"}  cellRender={ e => <div dangerouslySetInnerHTML = {{ __html: DOMPurify.sanitize(e.value) }}/>}/>
                                <Column alignment={"center"} caption={Config.lang("Ngay_vao_lam_du_kien")}
                                        dataField={"BeginDate"} dataType={"date"} format={"dd/MM/yyyy"} visible={false} />
                                <Column alignment={"center"} caption={Config.lang("Ngay_bo_sung_thoi_gian_vao_ra")}
                                        dataField={"WorkingDay"} dataType={"date"} format={"dd/MM/yyyy"}  visible={false} />
                                <Column
                                    alignment={"center"}
                                    cellRender={this.renderAction}/>
                            </GridContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount = async () => {
        await this.loadPermission();
        this.loadGrid();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dataGrid} = this.state;
        Config.callChildForm({
            FormID: "W75F8000",
            keyExpr: "TransID",
            data: dataGrid.rows,
            onLoad: (params) => {
                const rows =  _.filter(dataGrid.rows,["TransID", params.ID || ""]);
                const total = _.size(rows);
                this.setState({dataGrid: {rows, total}});
            },
            onAction: (e, data) => {this.setState({dataW75F8003: data, showW75F8003: true})}
        }, this.props);
    }
}

export default compose(connect((state) => (
    {
        getCboEmployees: state.general.getCboEmployees,
    }), (dispatch) => (
    {
        generalActions: bindActionCreators(generalActions, dispatch),
    }
)))(W75F8000)
