/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/8/2020
 * @Example Demo man hinh duyet chung...
 */
import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap"
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import Approvals from "../../../approvals/approvals";
import DateBoxPicker from "../../../common/form-material/date-box";
import {Combo, TextField} from "../../../common/form-material";

const styles = {

};
class W84F3000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };
    }

    componentDidMount() {
    }

    //Form demo..
    renderFormInfo = () => {
        const disabled = true;
        const loading = false;
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <TextField
                                label={Config.lang('DHR_Phieu_cham_cong')}
                                variant={"standard"}
                                margin={"normal"}
                                inputProps={{
                                    readOnly: disabled
                                }}
                                value={this.state.text}
                                disabled={loading}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <Combo
                                dataSource={[]}
                                displayExpr={'ProjectName'}
                                valueExpr={'ProjectID'}
                                margin={"normal"}
                                stylingMode={'underlined'}
                                disabled={loading}
                                selectProps={{
                                    readOnly: disabled
                                }}
                                shrink={true}
                                label={Config.lang('DHR_Du_an')}
                                // onValueChanged={(e) => this.handleChange("ProjectID", e)}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <DateBoxPicker label={Config.lang("DHR_Ngay_cham_cong")}
                                           stylingMode={"underlined"}
                                           margin={"normal"}
                                           shrink={true}
                                           width={"100%"}
                                           disabled={loading}
                                           dateBoxProps={{
                                               readOnly: disabled
                                           }}
                                           displayFormat={"longDate"}
                                // onValueChanged={(e) => this.handleChange('AttendanceDate', e)}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <Combo
                                dataSource={[]}
                                displayExpr={'HandOverName'}
                                valueExpr={'HandOverID'}
                                margin={"normal"}
                                stylingMode={'underlined'}
                                disabled={loading}
                                selectProps={{
                                    readOnly: disabled
                                }}
                                shrink={true}
                                label={Config.lang('DHR_Ban_giao')}
                                // onValueChanged={(e) => this.handleChange("HandOverID", e)}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label={Config.lang('DHR_Ghi_chu')}
                                variant={"standard"}
                                margin={"normal"}
                                inputProps={{
                                    readOnly: disabled
                                }}
                                disabled={loading}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                // onChange={(e) => this.handleChange('MasterNote', e)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    };

    onSelectionChanged = (e) => {
        console.log('=== onSelectionChanged', e);
    };

    render() {

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_chung")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                FromID={"W84F3000"}
                                formInfoRender={this.renderFormInfo} //render form info with every screen
                                onSelectionChanged={this.onSelectionChanged} //action when select row...
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(state => ({
    //reducers...
}), (dispatch) => ({
    //bind ations...
})), withStyles(styles))(W84F3000);
