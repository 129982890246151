/**
 * @copyright 2020 @ DigiNet
 * @author THANHHAI
 * @create 23/12/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W84F3005Actions from "../../../../redux/W8X/W84F3005/W84F3005_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";

import withStyles from "@material-ui/core/styles/withStyles";
import {useTheme} from "diginet-core-ui/theme";

import Modal from "../../../common/modal/modal";
import Typography from "@material-ui/core/Typography";
import { Typography as TypographyCore } from "diginet-core-ui/components";

import { Box } from "@material-ui/core";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Icons from "../../../common/icons/";
import { Loading } from "../../../common/loading/loading";

const coreTheme = useTheme();

const styles = (theme) => ({
    panel: {
        transition: "all .4s",
    },
    panelForm: {
        transition: "all .5s",
        opacity: 1,
    },
    hiddenOpacity: {
        opacity: 0,
    },
    leftPanelMinimum: {
        width: "74px",
    },
    rightPanelMinimum: {
        width: "calc(100% - 74px)",
    },
    titleColorFilter: {
        backgroundColor: theme.palette.primary.main + " !important",
        "& svg": {
            "& path": {
                fill: theme.palette.primary.contrastText,
            },
        },
    },
    approvalFlow: {
        margin: "0 20px",
        marginTop: 9,
        "&:not(:last-child)": {
            marginBottom: 20
        }
    },
    approvalFlowName: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: 28,
    },
    approvalFlowContent: {

    },
    viewRow:{
        marginRight: 30,
        position: 'relative',
        "&::before": {
            content: '""',
            height: "100%",
            width: 4,
            position: "absolute",
            top: 0,
            right: -30,
            background: coreTheme.colors?.brand12,
            borderRadius: 4
        },
    },
    approvalStatus: {
        "&:not(:last-child)": {
            borderBottom: "1px dashed #D4D5D9",
        },
        display: "flex",
    },
    approvalStatusName: {
        background: "#7F828E",
        minWidth: 127,
        width: 'fit-content',
        height: 32,
        borderRadius: 16,
        marginTop: 30,
        //text
        fontSize: 14,
        color: "#fff",
        fontWeight: 500,
        //
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::before": {
            content: '""',
            position: "absolute",
            width: 24,
            height: 1,
            background: " #7F828E",
            left: "100%",
        }
    },
    approvalLevel:{
        position: "absolute",
        right: -40,
        width: 24,
        height: 24,
        borderRadius: 4,
        background: coreTheme.colors?.dark6,
        zIndex:1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    approvalStatusName1: {
        background: "#0095FF",
    },
    approvalStatusName2: {
        background: "#00C875",
    },

    approvalStatusName4: {
        background: "#FF3D71",
    },
    approvalStatusName5: {
        background: "#FFAA00",
    },
    approval: {
        marginLeft: 25,
        "&:not(:first-child)": {
            marginTop: 15,
        },
    },
    dateIcon: {
        marginBottom: 4,
        marginRight: 3.33
    },
    mb05: {
        marginBottom: 4,
    },
    lh20: {
        lineHeight: "20px"
    },
    userImage: {
        marginRight: 9,

    }
});
const mapApprovalStateIcon = {
    0: <i className="far fa-file-alt" style={{ fontSize: 20 }}></i>,
    1: <Box display="flex" alignItems="flex-end" justifyContent="center">
        <i className="fas fa-circle fa-sm" style={{ fontSize: 4, marginRight: 1 }}></i>
        <i className="fas fa-circle" style={{ fontSize: 5, marginRight: 1 }}></i>
        <i className="fas fa-circle" style={{ fontSize: 6, marginRight: 1 }}></i></Box>,
    2: <i className="far fa-check" style={{ fontSize: 16 }}></i>,
    4: <i className="far fa-minus-circle" style={{ fontSize: 18 }}></i>,
    5: <i className="far fa-times" style={{ fontSize: 13 }}></i>,
};
class W84F3005 extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { formGridData: [], loading: false, widthStatus: {keyStatus: null, valueStatus: 0} };
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open &&this.props.TransID&& (prevProps.open !== this.props.open||prevProps.TransID !== this.props.TransID)) {
            this.setState({ loading: true ,formGridData: []}, () => this.loadGrid())

        }
    }

    componentDidMount() {
        let { TransID, open } = this.props;
        if (open && TransID) {

            this.setState({ loading: true,formGridData: [] }, () => this.loadGrid())
        }
    }

    loadGrid = (isReset) => {

        const { FormID, TransID } = this.props;
        //const { UserId } = Config.profile || {};
        const params = {
            // UserId,
            // DivisionID: Config.getDivisionID(),
            Language: Config.language || "84",
            VoucherID: TransID,
            FormID,
        };

        this.props.W84F3005Actions.getFormGrid(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            let newFormGridData = (data || {}).map((item) => {
                let { ApprovalFlowName, ApprovalLevel = [], ApprovalStatus = [] } = item || {};
                    ApprovalFlowName = ApprovalFlowName.map(item=>{

                        let approverCache=  Config.getUser({
                            EmployeeID: item.ApproverID && item.ApproverID[0],
                        }) || {}
                        let  approver = { ...approverCache,EmployeeName: item.ApproverName }
                        return {
                            ...item,
                            approver
                        }
                    })
                    ApprovalLevel=ApprovalLevel.sort(
                        (a, b) => a.ApprovalLevel - b.ApprovalLevel
                    ).map((ApprovalLevelItem) =>{
                        let ApprovalList = ApprovalFlowName.filter(item=>item.ApprovalLevel===ApprovalLevelItem.ApprovalLevel)
                        return {...ApprovalLevelItem,ApprovalList}
                    })
                    ApprovalFlowName= ApprovalFlowName[0]||{}
                    ApprovalFlowName= ApprovalFlowName.ApprovalFlowName ||''
                    return {
                        ApprovalFlowName,ApprovalLevel,ApprovalStatus
                    }



            });
            this.setState({ formGridData: newFormGridData, loading: false });
        });
    };

    render() {
        const { classes, open = true, onClose, customTitle } = this.props;
        const { formGridData = [], loading, widthStatus:{keyStatus, valueStatus} } = this.state;
        return (
            <React.Fragment>
                <Modal
                    open={open}
                    maxWidth={"md"}
                    fullWidth={true}
                    title={customTitle || Config.lang("DHR_Lich_su_duyet")}
                    onClose={onClose}
                >
                    <Modal.Content>

                        {loading ? <Box position="relative" minHeight={100}><Loading /></Box> : formGridData && formGridData.length ? formGridData.map((approvalFlow,approvalFlowIndex) => {
                            return (
                                <Box key = {approvalFlowIndex} className={classes.approvalFlow}>
                                    <Typography className={classes.approvalFlowName}>
                                        {approvalFlow.ApprovalFlowName}
                                    </Typography>
                                    <Box className={classes.approvalFlowContent}>
                                        {approvalFlow.ApprovalLevel
                                            ? approvalFlow.ApprovalLevel.map((level) => {

                                                return (
                                                    <Box key ={level.ApprovalLevel} className={classes.approvalStatus}>
                                                        <Box className={`display_row valign-bottom ${classes.viewRow}`}
                                                             style={{minWidth: keyStatus !== level.ApprovalStatusID ? valueStatus : 'fit-content'}}
                                                        >
                                                            <Box className={`${classes.approvalStatusName} ${classes[`approvalStatusName${level.ApprovalStatusID}`]}`}
                                                                 ref={el => {
                                                                     const width = parseInt(el?.getBoundingClientRect()?.width || 0);
                                                                     if(valueStatus < width)
                                                                     this.setState({
                                                                         widthStatus: {
                                                                             keyStatus: level.ApprovalStatusID,
                                                                             valueStatus: width
                                                                         }
                                                                     })
                                                                 }}
                                                            >
                                                                <Box className={'display_row align-center valign-middle'} style={{padding: '0 13px'}}>
                                                                    <Box mr={1} className={'display_row align-center valign-middle'}>
                                                                        {
                                                                            mapApprovalStateIcon[
                                                                                level.ApprovalStatusID
                                                                            ]
                                                                        }
                                                                    </Box>
                                                                    <TypographyCore type={"h3"} color={"white"}>
                                                                        {level.ApprovalStatusName}
                                                                    </TypographyCore>
                                                                </Box>
                                                                <Box className={classes.approvalLevel}>
                                                                    <TypographyCore color={"white"}
                                                                                    type={"h5"}
                                                                                    style={{whiteSpace: 'nowrap'}}
                                                                    >
                                                                        {level.ApprovalLevel}
                                                                    </TypographyCore>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{width: "60%", padding: '15px 0'}}>
                                                            {level.ApprovalList
                                                                ? level.ApprovalList.map((approval,approvalIndex) => {
                                                                    return (
                                                                        <Box
                                                                            key={approvalIndex}
                                                                            display="flex"
                                                                            className={` ${classes.approval}`}
                                                                        >
                                                                            <Box className={classes.userImage}>
                                                                                <UserImage
                                                                                    data={approval.approver}
                                                                                    width={48}
                                                                                    height={48}
                                                                                    allowHover={false}
                                                                                    style={{ marginTop: 5 }}
                                                                                />
                                                                            </Box>
                                                                            <Box>
                                                                                <Box fontSize={16} fontWeight={500} className={classes.mb05}>
                                                                                    {" "}
                                                                                    <UserName data={approval.approver}
                                                                                              allowHover={false}
                                                                                    />
                                                                                </Box>
                                                                                <Box display="flex" className={`${classes.mb05} ${classes.lh20}`} >
                                                                                    <Icons name="date" width={13.98} height={14.34} className={classes.dateIcon} />
                                                                                    <Typography>
                                                                                        {Config.helpers.convertDate(
                                                                                            approval.ApprovalDate,
                                                                                            "--/--/---- --:--:--",
                                                                                            "DD/MM/YYYY HH:mm:ss"
                                                                                        )}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Typography className={` ${classes.lh20}`}>
                                                                                    {approval.Notes || ""}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    );
                                                                })
                                                                : null}
                                                        </Box>
                                                    </Box>
                                                );
                                            })
                                            : null}
                                    </Box>
                                </Box>
                            );
                        }) : <div className={"display_row align-center valign-middle"}>
                                {Config.lang("DHR_Khong_co_du_lieu")}
                            </div>}
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

W84F3005.propTypes = {
    FormID: PropTypes.string,
    TransID: PropTypes.string,
    open: PropTypes.bool,

    onClose: PropTypes.func,
};

export default compose(
    connect(
        (state) => ({
            getFormGrid: state.W84F3005.getFormGrid,
        }),
        (dispatch) => ({
            W84F3005Actions: bindActionCreators(W84F3005Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
    withStyles(styles)
)(W84F3005);
