/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 12/15/2020
 * @Example
 */
import React                         from "react";
import {connect}                     from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config                        from "../../../../config";
import {Col, FormGroup, Row}         from "react-bootstrap"
import withStyles                    from "@material-ui/core/styles/withStyles";
import * as generalActions           from "../../../../redux/general/general_actions";
import * as W89F1000Actions          from "../../../../redux/W8X/W89F1000/W89F1000_actions";
import { Combo }                     from "../../../common/form-material";
import {DropDownButton, LoadPanel}              from 'devextreme-react';
import ButtonGeneral                 from "../../../common/button/button-general";
import Icons                         from "../../../common/icons/";
import {Divider, IconButton, Tooltip} from '@material-ui/core';
import moment                        from "moment";
import _                             from "lodash";
import { Checkbox, Radio, DatePicker, DateRangePicker } from 'diginet-core-ui/components';
import { Input }                     from "reactstrap";
import {Column}                      from "devextreme-react/data-grid";
import CloseIcon                     from '@material-ui/icons/Close';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {ScrollView} from 'devextreme-react';
import {browserHistory} from "react-router";
// import './w89f1000.scss';
const styles = (theme) => {

    return {
        btnExport: {
            margin: "4px 0 0 10px",
            borderRadius: 4,
            '& .dx-button-content': {
                backgroundColor: theme.palette.info.main,
                '& .dx-icon': {
                    color: "#FFFFFF",
                },
                '& .dx-button-text': {
                    fontSize: "0.9375rem",
                    color: "#FFFFFF",
                },
                '&:hover': {
                    opacity: 0.7,
                    backgroundColor: theme.palette.info.main
                },
            },
            '& .dx-state-disabled': {
                borderRadius: 4,
                '& .dx-button-mode-outlined': {
                  border: "none",
                },
                '& .dx-button-content': {
                    backgroundColor: theme.palette.action.disabled,
                    '& .dx-icon': {
                        color: "#8F9BB3",
                    },
                    '& .dx-button-text': {
                        fontSize: "0.9375rem",
                        color: "#8F9BB3",
                    }
                },
            },
            [theme.breakpoints.down("xs")]: {
                display: "flex",
                margin: 0
            }
        },
        titleReport: {
            whiteSpace: "nowrap",
            textAlign: "center",
        },
        styleCbo: {
            marginTop: 0
        },
        styleActionToolbar: {
            "& > div[class*='ActionToolbar-root-']": {
                padding: "0px !important",
            },
            [theme.breakpoints.down("xs")]: {
                alignItems: "center",
                flexDirection: "column"
            }
        },
        divCbo: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column"
            }
        },
        classBackdrop: {
            top: "113px",
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        layerDropdown: {
            zIndex: "1000 !important"
        }
    }
};
class W89F1000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            ReportID: "",
            ReportTypeID: "",
            dataCboReportID: [],
            dataFilterReportID: [],
            dataFilterPreview: [],
            dataForm: [],
            loading: {},
            requiredFieldNames: [],
            dataFilter: [
                { "id": "UserID", "type": "VarChar", "value": Config.profile.UserID },
                { "id": "DivisionID", "type": "VarChar", "value": Config.profile.DivisionID }
            ],
            showPreview: false,
            heightPreview: 0,
            downloadURL: "",
            loadingCboReportStructure: false,
            loadingCboReportType: false,
            downloadURLExport: "",
            downloadExt: "",
            downloadNameExport: "",
            showBackdrop: false,
        };
        this.loading = {};
        this.minHeightView = 550;
        this.refCombo = {};
        this.formMaster = null;
        this.refDropdown = null;
        this.chooseReportType = false;
        this.chooseReportID = false;
        this.refInput = null;
        this.refCheckbox = null;
        this.isLoadCboReportStructure = false;
        this.dataNotify = null;
        this.allowSetDataNotify = false;
        this.keyCallNotify = "";
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W89F1000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboReportType = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            Language: Config.language || 84,
            Type: Config.menuType,
        };
        this.setState({loadingCboReportType: true});
        this.props.w89f1000Actions.getCboReportType(params,(error) => {
            this.setState({loadingCboReportType: false});
            if (error) {
                Config.popup2.show('ERROR', error);
                return false;
            }
        });
    };

    handleData = (key, type, vl, cb) => {
        const {dataForm} = this.state;
        const dataFilterT = type === "DATETIME" ? this.state.dataFilter : [...this.state.dataFilter];
        const item = {
            id: key,
            type: type,
            value: vl
        };
        let isExit = false;
        dataFilterT.forEach((i, idx) => {
            if (i.id === key) {
                dataFilterT[idx] = item;
                isExit = true;
            }
        });
        if (!isExit) {
            dataFilterT.push(item);
        }
        this.setState({
            dataFilter: dataFilterT
        },  async () => {
            cb && cb();
            //xet data lai khi chon combo co ControlDepend(combo phu thuoc)
            const formInfo = dataForm.find(d => d.ControlID === key);
            const controls = dataForm.filter(
                (k) => k.DefaultValue === "D" && k.ControlDepend === formInfo.ControlID
            );
            let valueKeys = _.isArray(vl)
                ? vl
                : [vl];
            let dataDefault = {};
            for (let i = 0; i < controls.length; i++) {
                const control = controls[i];
                let dataControl = control.dataRows?.rows || [];
                dataDefault[control.ControlID] = dataControl.filter(d => valueKeys.includes(d[formInfo.valueExpr]));
            }
            if(dataDefault && Object.keys(dataDefault).length > 0) {
                for(let key of Object.keys(dataDefault)) {
                    const data = dataDefault[key];
                    const value = data.length > 0 ? data[0] : null;
                    const control = controls.find(c => c.ControlID === key);
                    if(control) {
                        const getData = () => {
                            return control.ControlType === "CC" ?
                                (value ? [value[control.valueExpr]] : []) :
                                (value ? value[control.valueExpr] : '');
                        };
                       await this.handleData(key,
                            control.ControlType === "CC" ? "Array" : "VarChar",
                             getData()
                            ,
                            () => {
                                this.setValueCombo(key, getData());
                            }
                        );
                    }

                }
            }
        })
    };

    getInfo = () => {
        const {location} = this.props;
        let dataNotify = {};
        if (location?.state &&
            location?.state?.form_id === "W89F1000" &&
            location?.state?.Type === 0
        ) {
            dataNotify = {...location.state, SalaryVoucherID: location.state.voucher_id};
            this.allowSetDataNotify = true;
            this.dataNotify = dataNotify;
            return {
                dataNotify
            };
        } else {
            return false
        }
    };

    _setLoading = (key, value, cb) => {
        if (!key) return false;
        if (typeof key === "object") {
            this.loading = {
                ...this.loading,
                ...key
            };
        } else {
            this.loading[key] = value;
        }
        this.setState({
            loading: {
                ...this.loading
            }
        }, () => {
            if (cb) cb();
        });
    };

    getHeightOfViewer = () => {
        let height = 0;
        if (this.formMaster) {
            const headerHeight = 100;
            const hMaster = this.formMaster.offsetHeight;
            const hw = window.innerHeight;
            height = hw - (hMaster + headerHeight + 90);
        }
        return height && height < this.minHeightView ? this.minHeightView : height;
    };

    loadItemCboReportStructure = () => {
        const { ReportTypeID, ReportID } = this.state;
        const params = {
            DivisionID: Config.getDivisionID(),
            ReportTypeID: ReportTypeID,
            ReportID: ReportID,
            CompanyID: Config.getDatabaseName(),
            Language: Config.language || 84,
            Type: Config.menuType,
        };
        this.setState({ loadingCboReportStructure: true });
        this.props.w89f1000Actions.getItemCboReportStructure(params,(error,dataStruct) => {
            this.setState({ loadingCboReportStructure: false });
            if (error) {
                Config.popup2.show('ERROR', error);
                return false;
            }
            if(dataStruct) {
                const requiredFieldNames = dataStruct.filter(item => item.IsRequired === 1).map(obj => (
                    {
                        id: obj.ControlID,
                        name: Config.language === "01" ? obj.Caption01 : obj.CaptionName84
                    }
                ));

                //sắp xếp thứ tự component hiện ra theo DisplayOrder
                const dataTemp = dataStruct.sort((a,b) => {
                    if(a.DisplayOrder < b.DisplayOrder) return -1;
                    if(a.DisplayOrder > b.DisplayOrder) return 1;
                    return 0;
                });

                //gán dataCombo, valueExpr, displayExpr cho combo
                dataStruct.forEach(struct => {
                    dataTemp.forEach((control, controlIdx) => {
                        if (control.ControlID === struct.ControlID && (control.ControlType === "C" || control.ControlType === "CC" || control.ControlType === "OPT")) {
                            struct.Field.forEach(type => {
                                // // set valueExpr and displayExpr
                                if (type.Style.includes("V")) {
                                    const styleV = struct.Field.find(f => f.Style.includes("V"));
                                    dataTemp[controlIdx].valueExpr = styleV.FieldName || "";
                                }//displayExpr
                                if (type.Style.includes("D")) {
                                    const styleV = struct.Field.find(f => f.Style.includes("D"));
                                    dataTemp[controlIdx].displayExpr = styleV.FieldName || "";
                                }//displayExpr
                            });
                        }
                    });
                });

                dataTemp.forEach(temp => {
                    // check dataFilter with default
                    if (temp.ControlType === "T") this.handleData(temp.ControlID, "VarChar", temp.DefaultValue);
                    if (temp.ControlType === "D") this.handleData(temp.ControlID, "VarChar", moment().format("YYYY-MM-DD"));
                    if (temp.ControlType === "CHK") this.handleData(temp.ControlID, "INT", parseInt(temp.DefaultValue) === 0 || parseInt(temp.DefaultValue) === 1 ? parseInt(temp.DefaultValue) : null);
                    if (temp.ControlType === "OPT") this.handleData(temp.ControlID, "VarChar", temp.DefaultValue ? temp.DefaultValue : null);
                });
                this.setState({
                    dataForm: dataTemp,
                    requiredFieldNames
                }, () => {
                    this.isLoadCboReportStructure = true;
                });
            }
        });
    };

    loadCboReportID = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            Language: Config.language || 84,
            Type: Config.menuType,
        };
        this.props.w89f1000Actions.getCboReportTypeID(params,(error) => {
            if (error) {
                Config.popup2.show('ERROR', error);
                return false;
            }
        });
    };

    componentDidMount = () => {
        this.loadCboReportType();
        this.loadCboReportID();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {getCboReportIDs} = this.props;
        const {dataNotify} = this.getInfo();
        if(prevState.ReportTypeID !== this.state.ReportTypeID) {
            if(this.chooseReportType) {
                this.setState({ReportID: ""});
            }
        }
        // set data when click notify
        if(getCboReportIDs && getCboReportIDs.length > 0) {
            const key = dataNotify?.form_id + dataNotify?.voucher_id; // key check notify
            if (dataNotify?.voucher_id && (!this.keyCallNotify || this.keyCallNotify !== key)) {
                this.keyCallNotify = key;
                this.setState({ReportTypeID: dataNotify?.ReportTypeID || "D13F4040"}, () => {
                    const dataFilterReportID = getCboReportIDs && getCboReportIDs.filter(data =>  data.ReportTypeID === this.state.ReportTypeID);
                    this.setState({dataFilterReportID,
                        dataForm: [],
                        dataFilter: [
                            { id: "UserID", type: "VarChar", value: Config.profile.UserID },
                            { id: "DivisionID", type: "VarChar", value: Config.profile.DivisionID},
                        ],
                        ReportID: "",
                    }, () => {
                        const dataReportDefault = dataFilterReportID.find(item => item?.IsDefault === 1);
                        const reportID = dataReportDefault?.ReportID || dataFilterReportID?.[0]?.ReportID;
                        this.setState({ReportID: reportID || ""}, () => {
                            const dataFilterPreview = dataFilterReportID.filter(data => data.ReportID === this.state.ReportID);
                            this.setState({dataFilterPreview});
                            this.loadItemCboReportStructure();
                        });
                    });
                })
            } else if (dataNotify?.voucher_id && this.keyCallNotify === key ) {
                this.keyCallNotify = "";
                browserHistory.push(Config.getRootPath() + dataNotify?.form_id);
            }
        }
    }

    handleChange = (key,e) => {
        if(!key) return false;
        switch (key) {
            case "ReportTypeID":
                const { getCboReportIDs } = this.props;
                this.setState({ReportTypeID: e.value}, () => {
                    this.setState({
                        dataForm: [],
                        dataFilter: [
                            { id: "UserID", type: "VarChar", value: Config.profile.UserID },
                            {
                                id: "DivisionID",
                                type: "VarChar",
                                value: Config.profile.DivisionID,
                            },
                        ],
                    });
                    this.chooseReportType = true;
                    if(e.value === null) {
                        this.chooseReportType = false;
                        if(this.refDropdown) {
                            this.refDropdown.instance.close();
                        }
                    }
                    if(this.isLoadCboReportStructure) {
                        this.isLoadCboReportStructure = false;
                    }
                    const dataFilterReportID = getCboReportIDs && getCboReportIDs.filter(data =>  data.ReportTypeID === this.state.ReportTypeID);
                    this.setState({dataFilterReportID});
                });
                break;
            case "ReportID":
                this.setState({ReportID: e.value}, () => {
                    this.chooseReportID = true;
                    if(this.chooseReportID) {
                        if(this.refInput) this.refInput.value = "";

                        if(this.refCheckbox) {
                            this.refCheckbox.setChecked(false);
                        }
                    }
                    if(e.value === null) {
                        this.chooseReportID = false;
                    }
                    const {dataFilterReportID, ReportID} = this.state;
                    const dataFilterPreview = dataFilterReportID.filter(data => data.ReportID === ReportID);
                    this.setState({dataFilterPreview});
                    if(!this.isLoadCboReportStructure) {
                        this.loadItemCboReportStructure();
                    }
                });
                break;
            default:
                break;
        }
    };

    renderRadio = (formInfo, idxForm) => {
        const { dataFilter } = this.state;
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        return (
            <Col key={idxForm}
                 className={"mgb10"}
                 lg={this.getColSpan(formInfo.ColSpan, 6)}
                 md={this.getColSpan(formInfo.ColSpan, 6)}
                 sm={this.getColSpan(formInfo.ColSpan, 6)}
                 xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                {formInfo.dataCombo && formInfo.dataCombo.map((combo, idxCombo) => {
                    const isChecked = formInfo?.DefaultValue === combo.value;
                    return (
                        <Radio
                            key={idxCombo}
                            defaultChecked={isChecked}
                            label={combo.Lable}
                            name={"radiogroup"}
                            onChange={e => {
                                this.handleData(formInfo.ControlID, "VarChar", e.value)
                            }}
                            value={combo.Value}
                        />
                    )
                })}
                {/*<Radio.Group style={{*/}
                {/*    border: '1px solid #d9d9d9', borderRadius: 2, padding: '4px 10px', width: '100%'*/}
                {/*}} disabled={disable} onChange={e => this.handleData(formInfo.ControlID, "VarChar", e.target.value)}*/}
                {/*             name={"radiogroup"}*/}
                {/*             defaultValue={formInfo.DefaultValue ? formInfo.DefaultValue : null}>*/}
                {/*    {formInfo.dataCombo && formInfo.dataCombo.map((combo, idxCombo) => {*/}
                {/*        return (*/}
                {/*            <Radio key={idxCombo}*/}
                {/*                   value={combo.Value}>*/}
                {/*                {combo.Lable}*/}
                {/*            </Radio>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</Radio.Group>*/}
            </Col>
        )
    };

    getColSpan = (vl, def) => {
        return vl ? vl : def;
    };

    checkDatePickerType = (format) => {
        let pickerType = "";
        let formatTimes = "YYYY-MM-DD";
        switch (true) {
            case format.length === 7: // mm/yyyy
                pickerType = "month";
                formatTimes = "YYYY-MM";
                break;
            case format.length === 4: // yyyy
                pickerType = "year";
                formatTimes = "YYYY";
                break;
            default:
                break;
        }
        return { pickerType, formatTimes };
    };

    checkRequiredStatusData = (disable, formInfo) => {
        const { dataFilter } = this.state;
        if (disable) {
            dataFilter.forEach(item => {
                if (item.id === formInfo.ControlID) {
                    if (_.isEmpty(item.value)) {
                        item.disable = true;
                    }
                }
            });
        } else {
            dataFilter.forEach(item => {
                if (item.id === formInfo.ControlID) {
                    if (_.isEmpty(item.value) && item.disable) {
                        item.disable = false;
                    }
                }
            });
        }
    };

    renderInput = (formInfo, idxForm) => {
        const { dataFilter } = this.state;
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(';').forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(',');
                dataFilter && dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(';').length);
        }
        const value = dataFilter.find(i => i.id === formInfo.ControlID);
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        return (
            <Col key={idxForm}
                 className={"mgb10"}
                 lg={this.getColSpan(formInfo.ColSpan, 6)}
                 md={this.getColSpan(formInfo.ColSpan, 6)}
                 sm={this.getColSpan(formInfo.ColSpan, 6)}
                 xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                <Input disabled={disable}
                       innerRef={ref => this.refInput = ref}
                       style={{
                           width: '100%',
                           border: "transparent",
                           boxShadow: "inset 0 0 0 1px #687799"
                       }}
                       defaultValue={value ? value.value : ""}
                       placeholder={formInfo.CaptionName84}
                       onChange={vl => this.handleData(formInfo.ControlID, "VarChar", vl.target.value)} />
            </Col>
        );
    };

    renderDatePicker = (formInfo, idxForm) => {
        const { dataForm, dataFilter } = this.state;
        const from = "From";
        const to = "To";
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value?.toString() === tmp?.toString()) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }
        let controlDateName = formInfo.ControlID;
        if (formInfo.ControlID.search(from) > -1) controlDateName = formInfo.ControlID.slice(0, formInfo.ControlID.length - from.length);
        if (formInfo.ControlID.search(to) > -1) controlDateName = formInfo.ControlID.slice(0, formInfo.ControlID.length - to.length);
        const controlDateFrom = controlDateName + "From";
        const controlDateTo = controlDateName + "To";
        const formatType = (formInfo.Format && formInfo.Format.toUpperCase()) || "";
        const { formatTimes } = this.checkDatePickerType(formatType);
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        // <=====================>Điều kiện Date cũ<=====================>
        // if (dataForm.find(i => i.ControlID === "DateTo") && dataForm.find(i => i.ControlID === "DateFrom") && formInfo.ControlID === "DateFrom"){
        // } else if (((formInfo.ControlID !== "DateTo" && formInfo.ControlID !== "DateFrom") || ((formInfo.ControlID === "DateTo" || formInfo.ControlID === "DateFrom") && (!dataForm.find(i => i.ControlID === "DateTo") || !dataForm.find(i => i.ControlID === "DateFrom"))))) {
        if (dataForm.find(item => item.ControlID === controlDateFrom) && dataForm.find(item => item.ControlID === controlDateTo)) {
            if (formInfo.ControlID === controlDateFrom) {
                let fieldName = Config.lang("Thoi_gian");
                const languageModeName = Config.language === "01" ? "Caption01" : "CaptionName84";
                const findFromToName = dataForm.filter(o => (o.ControlID === controlDateTo || o.ControlID === controlDateFrom));
                if (findFromToName.length === 2) {
                    fieldName = `${(findFromToName[0])[languageModeName]} - ${(findFromToName[1])[languageModeName]}`;
                }
                return (
                    <Col key={idxForm}
                         className={"mgb10"}
                         lg={this.getColSpan(formInfo.ColSpan, 6)}
                         md={this.getColSpan(formInfo.ColSpan, 6)}
                         sm={this.getColSpan(formInfo.ColSpan, 6)}
                         xs={12}
                    >
                        <label>{`${fieldName}`}</label>
                        <DateRangePicker
                            format={formatType || "DD/MM/YYYY"}
                            disabled={disable}
                            viewType={"outlined"}
                            clearAble
                            onChange={(date) => {
                                if(!date) return false;
                                const {value} = date;
                                this.handleData(controlDateFrom, "DATETIME", value && value[0] ? moment(value[0]).format(formatTimes) : null);
                                this.handleData(controlDateTo, "DATETIME", value && value[1] ? moment(value[1]).format(formatTimes) : null);

                            }}
                            defaultValue={[formInfo.DefaultValue === "@NowDate" ? moment() : null, formInfo.DefaultValue === "@NowDate" ? moment() : null]}
                        />
                        {/*<RangePicker style={{*/}
                        {/*             width: '100%',*/}
                        {/*             padding:"10px",*/}
                        {/*             border: "transparent",*/}
                        {/*             boxShadow: "inset 0 0 0 1px #687799",*/}
                        {/*             borderRadius: 4}}*/}
                        {/*             format={formatType || "DD/MM/YYYY"}*/}
                        {/*             locale={locale}*/}
                        {/*             popupStyle={{zIndex:1503}}*/}
                        {/*             disabled={disable}*/}
                        {/*             onCalendarChange={(date) => {*/}
                        {/*                 this.handleData(controlDateFrom, "DATETIME", date && date[0] ? moment(date[0]).format(formatTimes) : null);*/}
                        {/*                 this.handleData(controlDateTo, "DATETIME", date && date[1] ? moment(date[1]).format(formatTimes) : null);*/}

                        {/*             }}*/}
                        {/*             defaultValue={[formInfo.DefaultValue === "@NowDate" ? moment() : null, formInfo.DefaultValue === "@NowDate" ? moment() : null]}*/}
                        {/*/>*/}
                    </Col>
                )
            }
        } else {
            if (!_.isEmpty(formInfo)) {
                return (
                    <Col key={idxForm}
                         className={"mgb10"}
                         lg={this.getColSpan(formInfo.ColSpan, 6)}
                         md={this.getColSpan(formInfo.ColSpan, 6)}
                         sm={this.getColSpan(formInfo.ColSpan, 6)}
                         xs={12}
                    >
                        <label>{formInfo.CaptionName84}</label>
                        <DatePicker
                            placeholder={formInfo.CaptionName84}
                            clearAble={true}
                            viewType={"outlined"}
                            displayFormat={formatType || "DD/MM/YYYY"}
                            disabled={disable}
                            defaultValue={formInfo.DefaultValue === "@NowDate" ? moment() : null}
                            style={{ width: '100%' }}
                            onChange={e => {
                                const date = e?.value || null;
                                this.handleData(formInfo.ControlID, "DATETIME", date ? moment(date).format(formatTimes) : null)
                            }}
                        />
                        {/*<DatePicker style={{ width: '100%' }}*/}
                        {/*            placeholder={formInfo.CaptionName84}*/}
                        {/*            format={formatType || "DD/MM/YYYY"}*/}
                        {/*            {...(!_.isEmpty(pickerType) ? {*/}
                        {/*                picker: { pickerType }*/}
                        {/*            } : {})}*/}
                        {/*            locale={locale}*/}
                        {/*            disabled={disable}*/}
                        {/*            onChange={(date) => {*/}
                        {/*                this.handleData(formInfo.ControlID, "DATETIME", date ? moment(date).format(formatTimes) : null);*/}
                        {/*            }}*/}
                        {/*            defaultValue={formInfo.DefaultValue === "@NowDate" ? moment() : null}*/}
                        {/*/>*/}
                    </Col>
                )
            } else {
                return null
            }
        }
    };

    checkControlStatus = (formInfo) => {
        let controlStatus = false;
        let checkBoxChanged = false;
        let defaultOriginalValue = false;
        let currentDCEvalue = formInfo.DependControlEnabled;
        let currentDCEFieldName = formInfo.DependControlEnabled;
        const { dataForm, dataFilter } = this.state;
        if (!_.isEmpty(formInfo.DependControlEnabled)) {
            const valueIndex = currentDCEvalue.split(",")[1];
            const fieldNameIndex = currentDCEFieldName.split(",")[0];
            let bracketNameIndex = fieldNameIndex.indexOf("(");
            let bracketValueIndex = valueIndex.indexOf(")");
            if (bracketNameIndex === 0) bracketNameIndex = bracketNameIndex + 1;
            if (bracketNameIndex !== -1) currentDCEFieldName = fieldNameIndex.slice(bracketNameIndex, fieldNameIndex.length);
            if (bracketValueIndex !== -1) currentDCEvalue = _.toNumber(valueIndex.slice(0, bracketValueIndex));
            const ownerDepentControl = dataForm.find(item => item.ControlID === currentDCEFieldName);
            const ownerDepentControlChanged = dataFilter.find(item => item.id === currentDCEFieldName); //CheckBox 1 and 0 only
            const ownerDepentControlDevaultValue = _.toNumber(ownerDepentControl.DefaultValue);
            checkBoxChanged = ownerDepentControlChanged && ownerDepentControlChanged.value !== currentDCEvalue; // Nếu Control hiện tại là 1 thì ẩn Control có cùng ControlID
            defaultOriginalValue = ownerDepentControlDevaultValue !== currentDCEvalue;
            controlStatus = ownerDepentControlChanged && ownerDepentControlChanged.value === 1 ? checkBoxChanged : defaultOriginalValue;
        }
        return { controlStatus };
    };

    renderCheckBox = (formInfo, idxForm) => {
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            //<=====================>Điều kiện disable cũ<=====================>
            // disable = [];
            // formInfo.DependControlEnabled.split(';').forEach((depend) => {
            //     let temp = depend;
            //     temp = temp.slice(1, temp.length - 1);
            //     temp = temp.split(',');
            //     dataFilter.forEach(i => {
            //         const value = i.value ? i.value.toString() : i.value;
            //         const tmp = temp[1] ? temp[1].toString() : temp[1];
            //         if (i.id === temp[0] && value === tmp) {
            //             disable.push(false)
            //         }
            //     });
            // });
            // disable = !(disable.length === formInfo.DependControlEnabled.split(';').length);
            const { controlStatus } = this.checkControlStatus(formInfo);
            disable = controlStatus;
        }
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        return (
            <Col key={idxForm}
                 className={"mgb10"}
                 lg={this.getColSpan(formInfo.ColSpan, 6)}
                 md={this.getColSpan(formInfo.ColSpan, 6)}
                 sm={this.getColSpan(formInfo.ColSpan, 6)}
                 xs={12}
                 // style={{ minHeight: 57, display: 'flex', alignItems: 'center' }}
            >
                <Checkbox defaultChecked={parseInt(formInfo.DefaultValue) === 1}
                          ref={ref => this.refCheckbox = ref}
                          disabled={disable}
                          onChange={(e) => {
                              this.handleData(formInfo.ControlID, "INT", e.value ? 1 : 0);
                          }}>
                    {Config.language === "01" ? formInfo.Caption01 : formInfo.CaptionName84}
                </Checkbox>
            </Col>
        );
    };

    checkRequired = (requiredFieldNames, dataFilter) => {
        let requiredFieldName = [];
        if (!_.isEmpty(requiredFieldNames)) {
            dataFilter.forEach(obj => {
                if (_.isEmpty(obj.value)) {
                    const objName = requiredFieldNames.find(item => item.id === obj.id);
                    if (objName && objName.name) {
                        if (!obj.hasOwnProperty("disable")) { // Không có field  disable thì kiểm tra required bình thường
                            requiredFieldName.push(objName.name);
                        } else {// có field
                            if ((!obj.disable)) { // Và disable = false thì mới có thông báo
                                requiredFieldName.push(objName.name);
                            }
                        }
                    }
                }
            })
        }
        return { requiredFieldName };
    };

    changeValueNullToString = (array) => {
        if (!_.isArray(array) || _.isEmpty(array)) return null;
        const storeParams = array.map(obj => {
            const item = { ...obj };
            if (item && (_.isNull(item.value) || _.isUndefined(item.value))) {
                item.value = "";
            }
            else {
                if (_.isArray(item.value) && !_.isEmpty(item.value)) {
                    const customValue = item.value.map(valueStr => `''${valueStr}''`)
                    item.value = `(${customValue})`;
                }
            }
            return { ...item }
        });
        return storeParams;
    };

    previewTemplate = (ID) => {
        if (!ID) return;

        let url = Config.env.api + "/report/preview?";

        const params = {
            token: Config.token.id,
            id: ID
        };

        let param = Object.keys(params).map(function (k) {
            return k + "=" + params[k];
        }).join("&");

        url = url + encodeURIComponent(param);

        this.setState({
            showPreview: false,
            heightPreview: this.getHeightOfViewer(),
        }, () => {
            this.setState({
                downloadURL: url,
                showPreview: true
            }, () => {
                this._setLoading("Preview", true);
            });
        });
    };

    getExtFileName = (dataFileName) => {
        if(!dataFileName) return null;
        let fileName = "";
        switch (dataFileName) {
            case "pdf":
                fileName = "pdf";
                break;
            case "html":
                fileName = "html";
                break;
            case "xls":
            case "xlsx":
               fileName = "xlsx";
               break;
            case "doc":
            case "docx":
                fileName = "docx";
                break;
            default:
                fileName = "";
                break;
        }
        return fileName;
    };

    formatObjectToStringValue = (obj) => {
        if (!_.isObject(obj) || _.isEmpty(obj)) return null;
        const {dataForm} = this.state;
        let data = "";
        if(dataForm) {
            dataForm.forEach((control) => {
                if (control.ControlID) {
                    if(Object.keys(obj).includes(control.ControlID)) {
                        data = obj[control.ControlID];
                    }

                }
            })
        }
        return data;
    };

    formatArrayToString = (data) => {
        if(!data) return false;
        return data.join(',');
    };

    beforePreviewTemplate = () => {
        const { dataFilter, requiredFieldNames, dataFilterPreview, ReportTypeID, ReportID} = this.state;
        const { requiredFieldName } = this.checkRequired(requiredFieldNames, dataFilter);
        if (!_.isEmpty(requiredFieldName)) {
            const message = `${requiredFieldName.length > 1 ? Config.lang("Ban_phai_nhap_cac_thong_tin") : Config.lang("Ban_phai_nhap_thong_tin")}: ${requiredFieldName.join(", ")}`;
            Config.popup2.show("INFO", message);
            return null;
        }
        const isolateDataFilter = dataFilter.map(dataObj => { //remove disableFiled
            if (dataObj.hasOwnProperty("disable")) {
                dataObj = _.omit(dataObj, "disable");
            }
            return dataObj;
        });
        let dataParams = [];
        dataParams = isolateDataFilter.map((item) => {
            if(item.value && _.isArray(item.value)) {
                item.value = this.formatArrayToString(item.value)
            }
            else if(item.value && _.isObject(item.value)) {
                item.value = this.formatObjectToStringValue(item.value)
            }
            return item;
        });
        dataParams.push(
            { "id": "CompanyID", "type": "VarChar", "value": Config.getDatabaseName()},
            { "id": "TranMonth", "type": "VarChar", "value": Config.getHRTransMonth()},
            { "id": "TranYear", "type": "VarChar", "value": Config.getHRTransYear()},
            { "id": "ReportTypeID", "type": "VarChar", "value": ReportTypeID},
            { "id": "ReportID", "type": "VarChar", "value": ReportID}
            );
        dataParams = dataParams.map((item,indx) => {
            return {...item,key: String(indx)};
        });

        let dataFormat = this.changeValueNullToString(dataParams);
        dataFormat.forEach(item => {
            if(item.type === "Array") {
                item.value = item.value.split(",");
            }
            if(item.type === "DATETIME") {
                item.type = "VarChar";
            }
        });

        const params = {
            ReportTypeID: (dataFilterPreview && dataFilterPreview[0]?.ReportTypeID) || "",
            ReportID: (dataFilterPreview && dataFilterPreview[0]?.ReportID) || "",
            ExportType: (dataFilterPreview && dataFilterPreview[0]?.FileExt && this.getExtFileName(dataFilterPreview[0]?.FileExt)) || "xlsx",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || 84,
            Params: JSON.stringify(dataFormat),
            Type : Config.menuType
        };
        this._setLoading("Preview", true);
        this.props.w89f1000Actions.passParametersExport(params,(error,data) => {
        this._setLoading("Preview", false);
            if (error) {
                Config.popup2.show('ERROR', error);
                return false;
            }
            if (data && data.id) {
                this.previewTemplate(data.id);
            } else {
                Config.popup2.show("INFO", Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
        });
    };

    exportFile = (fileExt) => {
        const { ReportTypeID, ReportID, dataFilterPreview, dataFilter } = this.state;

        const isolateDataFilter = dataFilter.map(dataObj => { //remove disableFiled
            if (dataObj.hasOwnProperty("disable")) {
                dataObj = _.omit(dataObj, "disable");
            }
            return dataObj;
        });
        let dataParams = [];
        dataParams = isolateDataFilter.map((item) => {
            if(item.value && _.isArray(item.value)) {
                item.value = this.formatArrayToString(item.value)
            }
            else if(item.value && _.isObject(item.value)) {
                item.value = this.formatObjectToStringValue(item.value)
            }
            return item;
        });
        dataParams.push(
            { "id": "CompanyID", "type": "VarChar", "value": Config.getDatabaseName()},
            { "id": "TranMonth", "type": "VarChar", "value": Config.getHRTransMonth()},
            { "id": "TranYear", "type": "VarChar", "value": Config.getHRTransYear()},
            { "id": "ReportTypeID", "type": "VarChar", "value": ReportTypeID},
            { "id": "ReportID", "type": "VarChar", "value": ReportID}
        );
        dataParams = dataParams.map((item,indx) => {
            return {...item,key: String(indx)};
        });

        let dataFormat = this.changeValueNullToString(dataParams);
        dataFormat.forEach(item => {
            if(item.type === "Array") {
                item.value = item.value.split(",");
            }
            if(item.type === "DATETIME") {
                item.type = "VarChar";
            }
        });

        const params = {
            ReportTypeID: (dataFilterPreview && dataFilterPreview[0]?.ReportTypeID) || "",
            ReportID: (dataFilterPreview && dataFilterPreview[0]?.ReportID) || "",
            ExportType: fileExt || "xlsx",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || 84,
            Params: JSON.stringify(dataFormat),
            Type: Config.menuType,
        };
        this.props.w89f1000Actions.exportFileTemplate( params,(error,data) => {
            if (error) {
                Config.popup2.show('ERROR', error);
                return false;
            }
            if(data) {
                this.setState({
                    downloadURLExport: data.URL ? data.URL : "",
                    downloadExt: data.fileExt ? data.fileExt : "",
                    downloadNameExport: data.fileName ? decodeURI(data.fileName) : "",
                }, () => {
                    let el = document.getElementById("linkExportExcel_1");
                    el.click();
                });
            }
        });
    };

    clearCombo = (comboChildren) => {
        if(this.refCombo) {
            this.refCombo[comboChildren.ControlID].setValue(null);
        }
    };

    clearDataNotify = () => {
        this.allowSetDataNotify = false;
        this.dataNotify = null;
    };

    setValueCombo = (control,value) => {
        if(this.refCombo) {
            this.refCombo[control].setValue(value);
        }
    };
    loadedOnPreview = () => {
        this._setLoading("Preview", false);
    };

    onClosePreview = () => {
        this.loading = {};
        this.setState({ showPreview: false, loading: this.loading });
    };

    hiddenDropdown = () => {
        this.setState({showBackdrop: false});
        if(this.refDropdown) {
            this.refDropdown.instance.close();
        }
    };


    dropDownContentComponent = () => {
        const { classes } = this.props;
        const { dataFilterReportID, ReportID, ReportTypeID, dataForm, dataFilter, loadingCboReportStructure } = this.state;
        return (
            <ScrollView width='100%' height='100%'>
                <FormGroup style={{padding: "0px 15px 0px 15px"}}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Combo
                                label={Config.lang("Mau_bao_cao")}
                                dataSource={dataFilterReportID}
                                displayExpr={"ReportName"}
                                valueExpr={"ReportID"}
                                className={"mgt15"}
                                onValueChanged={e => this.handleChange("ReportID", e)}
                                showClearButton={true}
                                value={ReportID || ""}
                            />
                        </Col>
                    </Row>
                    {ReportID && <Divider style={{margin: "10px 0px"}}/>}

                    <Row>
                        <div ref={ref => this.formMaster = ref}>
                            {ReportTypeID && ReportID && dataForm && dataForm.map((formInfo, idxForm) => {
                                if(formInfo.IsHide) return null;
                                switch (formInfo.ControlType) {
                                    case "T": return this.renderInput(formInfo,idxForm);
                                    case "C":
                                    case "CC":
                                        return <SelectCus key={idxForm}
                                                          formInfo={formInfo}
                                                          reportID={ReportID}
                                                          reportTypeID={ReportTypeID}
                                                          idxForm={idxForm}
                                                          classes={classes}
                                                          dataNotify={this.dataNotify}
                                                          allowSetDataNotify={this.allowSetDataNotify}
                                                          clearDataNotify={this.clearDataNotify}
                                                          ref={ref =>  this.refCombo[formInfo.ControlID] = ref}
                                                          w89f1000Actions={this.props.w89f1000Actions}
                                                          dataFilter={dataFilter}
                                                          dataForm={dataForm}
                                                          clearCombo={this.clearCombo}
                                                          setValueCombo={this.setValueCombo}
                                                          getColSpan={this.getColSpan}
                                                          handleData={this.handleData}
                                                          checkRequiredStatusData={this.checkRequiredStatusData}
                                        />;
                                    case "D": return this.renderDatePicker(formInfo, idxForm);
                                    case "CHK": return this.renderCheckBox(formInfo, idxForm);
                                    case "OPT": return this.renderRadio(formInfo, idxForm);
                                    default: return null;
                                }

                            })}
                        </div>
                    </Row>
                    {ReportID && <Divider style={{margin: "0px 0px 15px 0px"}}/>}
                        <ButtonGeneral
                            name={Config.lang("Dong_y")}
                            disabled={!ReportID || loadingCboReportStructure}
                            className={"pull-right"}
                            variant={"contained"}
                            color={"info"}
                            style={{textTransform: "uppercase", marginBottom: 10}}
                            icon={<Icons className={"far fa-check-square"}/>}
                            // size={"medium"}
                            onClick={() => {
                                this.hiddenDropdown();
                                this.beforePreviewTemplate();
                            }}
                        />
                </FormGroup>

            </ScrollView>
        )
    };

    onShown = (e) => {
        const { classes } = this.props;
        if(!e) return null;
        e.component.content().closest('.dx-overlay-wrapper').classList.add(classes.layerDropdown);
    };

    onButtonClick = (e) => {
        if(!e) return null;
        if(!e.component.option('opened')) {
            this.setState({showBackdrop: true});
        } else {
            this.setState({showBackdrop: false});
        }
    };

    checkTypeExt = (downloadURL,CheckExtFileName) => {
        let link = "";
        switch (CheckExtFileName) {
            case "pdf":
            case "doc":
            case "docx":
                link = "https://docs.google.com/gview?url=" + this.state.downloadURL + "&embedded=true";
                break;
            case "xls":
            case "xlsx":
                link = "https://view.officeapps.live.com/op/embed.aspx?src=" + this.state.downloadURL;
                break;
            case "html":
                link = decodeURIComponent(this.state.downloadURL);
                break;
            default:
                break;
        }
        return link;
    };

    render() {
        const { classes, getCboReportTypes, getCboReportIDs } = this.props;
        const { ReportTypeID, showPreview, heightPreview, loading, loadingCboReportType,
            ReportID, loadingCboReportStructure, dataFilterPreview, showBackdrop, downloadURL} = this.state;
        let CheckExtFileName =  (dataFilterPreview && dataFilterPreview[0]?.FileExt) || "";
        let dataReportID = getCboReportIDs && ReportID && getCboReportIDs.find(f => f.ReportID === ReportID);
        let width = window.innerWidth;
        const heightToolbar = document.querySelector('.action-toolbar')?.clientHeight || 62;
        const positionPopover = {
            my: 'top',
            at: width <= 425 ? "center" : 'left',
            offset: {
                x: width <= 425 ? 0 : -30,
                y: 26
            },
            of: '#dropDownReportType'
        };
        return (
            <React.Fragment>
                <ActionToolbar className={classes.styleActionToolbar} alignment={'space-between'} width={"100%"}>
                    <div className={"display_row align-center"}>
                        <div className={classes.divCbo}>
                            <div style={{whiteSpace:"nowrap",marginRight: 5}}>{Config.lang("bao_cao")}</div>
                            <Combo
                                style={{width: 250, minWidth: 250, margin: 0}}
                                dataSource={getCboReportTypes}
                                loading={loadingCboReportType}
                                disabled={loading.Preview}
                                displayExpr={"ReportTypeName"}
                                placeholder={Config.lang("Chon_bao_cao")}
                                valueExpr={"ReportTypeID"}
                                onValueChanged={e => this.handleChange("ReportTypeID", e)}
                                showClearButton={true}
                                value={ReportTypeID}
                            />
                            <DropDownButton
                                text={Config.lang("Chon_bao_cao")}
                                icon="far fa-check-square"
                                id={"dropDownReportType"}
                                disabled={!ReportTypeID || loading.Preview}
                                ref={ref => this.refDropdown = ref}
                                style={{marginTop: 0}}
                                elementAttr={{class: classes.btnExport}}
                                stylingMode={"outlined"}
                                width={"100%"}
                                onButtonClick={this.onButtonClick}
                                dropDownContentComponent={this.dropDownContentComponent}
                                dropDownOptions={{
                                    closeOnOutsideClick: false,
                                    minHeight: 100,
                                    maxHeight: window.innerHeight - (heightToolbar + 50),
                                    overFlowX: "auto",
                                    fullScreen: width <= 500,
                                    showCloseButton: width <= 500,
                                    showTitle: width <= 500,
                                    dragEnabled: true,
                                    // ...(width <= 500 ? {elementAttr: 'style="height: "100%"; overflow: "auto"'} : {}),
                                    width: width <= 500 ? width : 500,
                                    onShown: (e) => this.onShown(e),
                                    ...(width > 768 ? {position: positionPopover} : {})
                                }}
                            />
                        </div>
                    </div>
                    <div className={"display_row align-center pull-right"}>
                        <Tooltip title={Config.lang("Xuat_Word")}>
                            <IconButton
                                component="div"
                                disabled={
                                    !ReportID ||
                                    loading.Preview ||
                                    loadingCboReportStructure ||
                                    (CheckExtFileName !== "doc" && CheckExtFileName !== "docx" && CheckExtFileName !== "html")}
                                style={{color:"#27569b", padding: '6px 12px'}}
                                aria-label="view"
                                size="medium"
                                onClick={() => {
                                    this.hiddenDropdown();
                                    this.exportFile("docx");
                                }}
                            >
                                <Icons className="fas fa-file-word"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Config.lang("Xuat_Excel")}>
                            <IconButton
                                component="div"
                                disabled={
                                    !ReportID ||
                                    loading.Preview ||
                                    loadingCboReportStructure ||
                                    (CheckExtFileName !== "xlsx" && CheckExtFileName !== "xls")}
                                style={{color:"#008000", padding: '6px 12px'}}
                                aria-label="view"
                                size="medium"
                                onClick={() => {
                                    this.hiddenDropdown();
                                    this.exportFile("xlsx");
                                }}
                            >
                                <Icons className="fas fa-file-excel"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Config.lang("Xuat_PDF")}>
                            <IconButton
                                component="div"
                                style={{color:"#f00", padding: '6px 12px'}}
                                disabled={
                                    !ReportID ||
                                    loading.Preview ||
                                    loadingCboReportStructure ||
                                    (CheckExtFileName !== "pdf" && CheckExtFileName !== "html")
                                }
                                aria-label="view"
                                size="medium"
                                onClick={() => {
                                    this.hiddenDropdown();
                                    this.exportFile("pdf");
                                }}
                            >
                                <Icons className="fas fa-file-pdf"/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </ActionToolbar>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <h4 className={classes.titleReport}>{(dataReportID && dataReportID?.ReportName) || ""}</h4>
                    </Col>
                </Row>
                <Row>
                    <div className={ showBackdrop ? classes.classBackdrop : ""} style={{top: heightToolbar + 50}}/>
                    {showPreview && <Col xs={12} sm={12} md={12} lg={12}>
                        <div className={"display_row align-center valign-bottom"}>
                            <IconButton aria-label={"close"} size={"small"} onClick={this.onClosePreview}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className={"display_row align-center valign-middle"}>
                            <iframe id={"iframe_id"}
                                    src={this.checkTypeExt(downloadURL,CheckExtFileName)}
                                    title={this.state.downloadName}
                                    onLoad={this.loadedOnPreview}
                                    height={heightPreview}
                                // className={classes.view}
                                    width={"100%"} />
                        </div>
                    </Col>}
                </Row>
                <LoadPanel
                    shadingColor='rgba(0,0,0,0.4)'
                    position={{ my: 'center', of: "#root"}}
                    visible={loading?.Preview}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <a id="linkExportExcel_1" className="hide" download={this.state.downloadNameExport}
                   href={this.state.downloadURLExport}>File</a>
            </React.Fragment>
        )
    }
}


class SelectCus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataCboControl: {},
            data: null,
            dataCbo: {
                total: 0,
                rows: []
            },
            value: null,
        };
        this.filter = {
            skip: 0,
            limit: 50,
            strSearch: ""
        };
        this.isLoadeddCboControlData = false;
        // this.dataCbo = {};
    }

    setValue = (value, controlType) => {
        controlType === "CC" ? this.setState({
            ...value,
            value
        }) : this.setState({ value })
    };

    loadCboControlData = (dataLoadMore = {}, isReset = false) => {
        const { skip, limit, strSearch } = this.filter;
        const { formInfo, reportID, handleData, dataForm, dataNotify, allowSetDataNotify, clearDataNotify } = this.props;
        const params = {
            search: dataLoadMore && dataLoadMore.strSearch ? dataLoadMore.strSearch : strSearch,
            DivisionID: Config.getDivisionID(),
            ReportTypeID: formInfo && formInfo.ReportTypeID ? formInfo.ReportTypeID : "",
            ReportID: reportID || "",
            CompanyID: Config.getDatabaseName(),
            Language: Config.language || 84,
            ControlID: formInfo && formInfo.ControlID ? formInfo.ControlID : "" ,
            skip : dataLoadMore && dataLoadMore.skip ? dataLoadMore.skip : skip,
            limit : dataLoadMore && dataLoadMore.limit ? dataLoadMore.limit : limit,
            Type: Config.menuType,
        };
        this.setState({loading: true});
        this.props.w89f1000Actions.getCboControlData(params,  (error,data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup2.show('ERROR', error);
                return false;
            }
            if(data) {
                let valueCbo = "";
                const { dataCbo, value } = this.state;
                const dataCombo = {
                    total: data && data.total ? data.total : data.length,
                    rows: data && data.rows ? (!isReset && dataLoadMore && dataLoadMore.IsLoadmore ? dataCbo.rows.concat(data.rows) : data.rows) : data
                };
                // set data default combo by dataNotify
                if(allowSetDataNotify && formInfo && dataNotify && dataNotify[formInfo?.ControlID]) {
                    valueCbo = dataCombo?.rows?.find(item => item[formInfo?.ControlID] === dataNotify[formInfo?.ControlID]);
                    if(_.isEmpty(valueCbo) && formInfo?.ControlID === "EmployeeID") {
                        const dataEmployee = Config.getUser({EmployeeID: dataNotify[formInfo?.ControlID] || ""});
                        dataCombo.rows = dataCombo.rows.concat(dataEmployee);
                        valueCbo = dataEmployee;
                    }
                }
                // set value DefaultValue
                if(!this.isLoadeddCboControlData && !allowSetDataNotify) {
                    if((formInfo.ControlType === "C" || formInfo.ControlType === "CC")) {
                        if(!formInfo.ControlDepend &&formInfo.DefaultValue === "D") {
                            valueCbo = (dataCombo && dataCombo.rows && dataCombo.rows[0]);
                        }
                    }
                }

                if(dataForm) {
                    dataForm.forEach((control, controlIdx) => {
                        if (control.ControlID === formInfo.ControlID && (control.ControlType === "C" || control.ControlType === "CC")) {
                            if (!dataForm[controlIdx].dataRows) dataForm[controlIdx].dataRows = dataCombo;
                        }
                    })
                }

                if(this.isLoadeddCboControlData) {
                    valueCbo = value;
                }
                this.setState({
                    dataCbo: dataCombo,
                    value: valueCbo
                },() => {
                    if(!this.isLoadeddCboControlData) {
                        if (handleData)
                            handleData(
                                formInfo.ControlID,
                                formInfo.ControlType === "CC" ? "Array" : "VarChar",
                                formInfo.ControlType === "CC" ? (valueCbo ? [valueCbo[formInfo.valueExpr]] : [])  :
                                    (formInfo.ControlType === "C" && formInfo.IsLoadmore === 1) ? (valueCbo || null) :
                                        ((valueCbo && valueCbo[formInfo.valueExpr]) || null)
                            );

                    }
                    dataNotify && clearDataNotify && clearDataNotify();
                    this.isLoadeddCboControlData = true;
                });
            }
        })
    };

    componentDidMount() {
        this.loadCboControlData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.reportTypeID !== this.props.reportTypeID){
            this.isLoadeddCboControlData = false;
        }
    }
    render() {
        let { dataFilter, dataForm, formInfo, idxForm, handleData, clearCombo, getColSpan, checkRequiredStatusData, classes } = this.props;
        let { value, loading, dataCbo } = this.state;
        let languageCboName = Config.language === "01" ? "Caption01" : "CaptionName84";
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    if (i.id === temp[0] && i.value && temp[1] && i.value.toString() === temp[1].toString()) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }
        //check phụ thuộc combo

        let dataDepend = dataCbo.rows;
        if (formInfo.ControlDepend) {
            formInfo.ControlDepend.split(";").forEach(depend => {
                dataFilter.forEach(i => {
                    if (i.id === depend && i.value && i.value !== "%" && dataDepend) {
                        const control = dataForm.find(k => k.ControlID === depend);
                        // let arrkey = [];
                        // arrkey = _.isArray(i.value)
                        //     ? i.value.map((item) => item[control.valueExpr])
                        //     : [];
                        if(_.isArray(i.value)) {
                            dataDepend = i.value.length > 0 ?
                                dataDepend.filter(obj => i.value.includes(obj[control.valueExpr])) :
                                dataDepend;
                        } else {
                            dataDepend = dataDepend.filter(j => j[control.valueExpr] === i.value);
                        }
                        // valueCombo = dataDepend && dataDepend.length > 0 ? dataDepend[0] : null;
                        // console.log('this.chooseValueCombo', this.dataCbo[formInfo.valueExpr]);
                        // if(this.dataCbo) {
                        //     this.dataCbo[formInfo.valueExpr].instance.option("value", valueCombo);
                        //     // this.chooseValueCombo = true;
                        // }
                    }
                }
                );
            })
        }

        checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        const controlChildren = dataForm.find(k => formInfo.ControlID === k.ControlDepend);
        const valueCombo = formInfo.ControlType === "CC" ? (!_.isEmpty(value) && _.isArray(value) ? value :((value && [value]) || null)) : value;
        return (
            <Col key={idxForm}
                 className={"mgb10"}
                 lg={getColSpan(formInfo.ColSpan, 6)}
                 md={getColSpan(formInfo.ColSpan, 6)}
                 sm={getColSpan(formInfo.ColSpan, 6)}
                 xs={12}
            >
                <label>{formInfo[languageCboName]}</label>
                        <Combo.LoadMore
                            // reference={ref => this.dataCbo[formInfo.valueExpr] = ref}
                            dataSource={dataDepend}
                            totalItems={dataCbo.total}
                            skip={this.filter.skip}
                            limit={this.filter.limit}
                            displayExpr={formInfo.displayExpr}
                            keyExpr={formInfo.valueExpr}
                            valueExpr={formInfo.valueExpr}
                            disabled={disable}
                            loading={loading}
                            value={valueCombo}
                            gridProps={
                                (formInfo && formInfo.IsLoadmore ? formInfo.IsLoadmore === 1 : false) ? {} :
                                {
                                    filterRow: {
                                    visible: !(formInfo.IsLoadmore === 1),
                                    showOperationChooser: false,
                                    }
                                }
                            }
                            className={classes.styleCbo}
                            multiple={formInfo.ControlType === "CC"}
                            showColumnHeaders={true}
                            acceptCustomValue={formInfo.IsLoadmore === 1}
                            stylingMode={'outlined'}
                            // label={Config.lang('DHR_Nhan_vien')}
                            // itemRender={(e) => {
                            //     const {data} = e;
                            //     if (!data) return null;
                            //     return data.EmployeeID + ' - ' + data.EmployeeName;
                            // }}

                            showClearButton={true}
                            onValueChanged={async (e) => {
                                const valueT =
                                            formInfo.ControlType === "CC" ||
                                            (formInfo.IsLoadmore === 1 && formInfo.ControlType === "C")
                                                ? e.value
                                                : (e.value && e.value[formInfo.valueExpr]) || null;
                                let valueCboTypeCC = _.isArray(valueT) ? valueT.map(item => item[formInfo.valueExpr]) : [];
                                this.setValue(valueT, formInfo.ControlType);
                                if (handleData)
                                    await handleData(
                                        formInfo.ControlID,
                                        formInfo.ControlType === "CC" ? "Array" : "VarChar",
                                        formInfo.ControlType === "CC" ? valueCboTypeCC :   valueT,
                                        async  () => {
                                            // xoa data combo phu thuoc
                                            if (controlChildren && handleData && clearCombo) {
                                                clearCombo(controlChildren);
                                                await handleData(controlChildren.ControlID, "VarChar", null);
                                            }
                                        },
                                        formInfo
                                    );
                            }}


                            // showCheckBoxMode={"none"}
                            onInputChanged={(e) => {
                                if(formInfo.IsLoadmore === 1) {
                                    const dataLoadMore = {
                                        strSearch: e.target.value,
                                        skip: e.skip,
                                        limit: e.limit,
                                        IsLoadmore: formInfo.IsLoadmore
                                    };
                                    this.loadCboControlData(dataLoadMore, true);
                                }
                            }}
                            onLoadMore={(e) => {
                                if(formInfo.IsLoadmore === 1) {
                                    const dataLoadMore = {
                                        strSearch: this.filter.strSearch,
                                        skip: e.skip,
                                        limit: e.limit,
                                        IsLoadmore: formInfo.IsLoadmore
                                    };
                                    this.loadCboControlData(dataLoadMore, false);
                                }

                            }}
                        >
                             {formInfo.Field && formInfo.Field.map((field, idxField) => {
                                 this.value = value;
                                return (
                                    <Column key={idxField}
                                            dataField={field.FieldName}
                                            caption={field["Caption" + (Config.language || "84")]}
                                            visible={field.IsHide === 0} />
                                )
                            })}
                        </Combo.LoadMore>
            </Col>
        );
    }
}


export default compose(connect((state) => ({
    getCboReportTypes: state.W89F1000.getCboReportTypes,
    getCboReportIDs: state.W89F1000.getCboReportIDs,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w89f1000Actions: bindActionCreators(W89F1000Actions, dispatch),
})
), withStyles(styles,{withTheme: true}))(W89F1000);

