import React, { useEffect, useRef, useState } from "react";
import {
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    Col,
    Row,
    Typography,
    ButtonIcon,
    Popover,
} from "diginet-core-ui/components";
import * as W82F1000Actions from "../../../redux/W8X/W82F1000/W82F1000_actions";
import * as generalActions from "../../../redux/general/general_actions";
import Config from "../../../config";
import GridContainer from "../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";

const FormID = "W82F1000";
const W82F1000 = props => {
    const { open, onClose, data } = props;

    const [dataColumn, setDataColumn] = useState([]);
    const [dataGrid, setDataGrid] = useState([]);
    const [gridLoading, setGridLoading] = useState(false);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [permission, setPermission] = useState({});
    const [disbaledMore, setDisbaledMore] = useState(true);

    const refGrid = useRef(null);
    const refPopover = useRef(null);
    const refContainerToPrint = useRef(null);
    const tmpSelectedRowData = useRef([]);
    const tmpDataGrid = useRef([]);
    const isChangeDataGrid = useRef(false);
    const hasFixedColumn = useRef(false);
    const isPrint = useRef(false);

    const dispatch = useDispatch();

    const loadColumn = () => {
        const params = {
            AlertBaseID: data?.AlertBaseID ?? "",
            Language: Config.language || "84",
        };
        dispatch(
            W82F1000Actions.loadColumn(params, (error, dt) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                hasFixedColumn.current = dt.some(f => f.ControlFormat === "F");
                setDataColumn(dt || []);
            })
        );
    };

    const loadGrid = () => {
        const params = {
            SQLQuery: data?.SQLQuery ?? "",
            AlertCode: data?.AlertCode ?? "",
            AlertBaseID: data?.AlertBaseID ?? "",
            ModuleID: data?.ModuleID ?? "",
            DivisionID: data?.DivisionID ?? "",
            PeriodTime: data?.PeriodTime ?? "",
        };
        setGridLoading(true);
        dispatch(
            W82F1000Actions.loadGrid(params, (error, dt) => {
                setGridLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                const _dataGrid = dt?.length > 0 ? dt.map((m, idx) => ({ ...m, ID: idx })) : [];
                tmpDataGrid.current = _dataGrid;
                setDataGrid(_dataGrid);
            })
        );
    };

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission("W51F2200", isPer => {
                setPermission({ W51F2200: isPer });
            })
        );
    };

    useEffect(() => {
        loadPermission();
        loadColumn();
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isChangeDataGrid.current) {
            isChangeDataGrid.current = false;
            isPrint.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    const onExportExcel = () => {
        if (refGrid.current) {
            refGrid.current.instance.exportToExcel(true);
        }
    };

    const onSelectionChanged = e => {
        const { currentDeselectedRowKeys, currentSelectedRowKeys, selectedRowsData } = e;
        if (currentDeselectedRowKeys.length > 0) {
            tmpSelectedRowData.current = tmpSelectedRowData.current?.filter(e => {
                return currentDeselectedRowKeys.indexOf(e.ID) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach(val => {
                if (selectedRowsData.length > 0) {
                    const data = selectedRowsData.find(d => d.ID === val);
                    if (data) tmpSelectedRowData.current.push(data);
                }
            });
        }
        setDisbaledMore(tmpSelectedRowData.current.length === 0);
        e.component.selectRows(e.selectedRowKeys);
    };

    const onPrint = () => {
        if (tmpSelectedRowData.current.length > 0) {
            isChangeDataGrid.current = true;
            setIsLoadingPrint(true);
            setDataGrid(tmpSelectedRowData.current);
        }
    };

    const onContentReady = async () => {
        if (isPrint.current) {
            if (refContainerToPrint.current) {
                await html2canvas(refContainerToPrint.current, { useCORS: true, allowTaint: true, scale: 1 }).then(
                    function (canvas) {
                        try {
                            const image = canvas.toDataURL("image/png");
                            const myWindow = window.open("", `${data?.AlertMessage ?? ""}`);
                            myWindow.document.write(
                                `<body onload="window.print();window.close();"><img style="max-width: 100%" alt="" src=${image} /></body>`
                            );
                            myWindow.document.close();
                            myWindow.focus();
                            setIsLoadingPrint(false);
                            setDataGrid(tmpDataGrid.current);
                        } catch (err) {
                            Config.popup.show("ERROR", err);
                        }
                    }
                );
            }
            isPrint.current = false;
        }
    };

    const renderHeaderCell = column => {
        return <Typography type={"h5"}>{column?.FieldCaption ?? ""}</Typography>;
    };

    /**
     * >Lập đề xuất cấp  phát  CCLĐ<
     * */
    const issueEquipment = () => {
        // Bước 1: Lưu bảng tạm: chỉ lưu distinct EmployeeID những dòng check

        // Lấy danh sách EmployeeID từ lưới những dòng đã check
        let selectedItems = refGrid.current.instance.getSelectedRowsData();

        const params = {
            FormID: FormID,
            TransGroupID: "",
            employees: selectedItems?.flatMap(i => i.EmployeeID) || [],
        };
        dispatch(
            W82F1000Actions.issueEquipment(params, (err, data) => {
                if (err) Config.popup.show("ERROR", err);
                else {
                    // Bước 2: 1.Gọi màn hình Đề xuất cấp phát công cụ lao động - W51F2201 mode thêm mới (mode:'add')
                    browserHistory.push({
                        pathname: Config.getRootPath() + "W51F2201",

                        state: {
                            mode: "add",
                            // Bước 2: 2.Gọi SP để load lưới 1 state: {issueEquipment: data} chính là data lưới 1
                            issueEquipment: data,

                            // Bước 3: Load lưới 2:Truyền @EquipmentGroupID , @EquipmentID, @UnitID, @UnitName, @Quantity, @UsingPeriod, @UsingPeriodFrom, @UsingPeriodTo
                            // từ những dòng check (chính là state: {selectedItems}) tại cảnh báo sang W51F2201 load lưới 2,
                            // group tương ứng theo từng @EmployeeID lưới 1.
                            // thao tác bên màn hình W51F2201 => task WHRP-2621
                            selectedItems,
                        },
                    });
                }
            })
        );
    };

    return (
        <>
            <Modal onClose={() => onClose(true)} open={open} width={"100%"}>
                <ModalHeader>{Config.lang("Canh_bao_nhac_viec")}</ModalHeader>
                <ModalBody ref={refContainerToPrint}>
                    <div className={"display_row align-between align-center"}>
                        <Typography>{data?.AlertMessage ?? ""}</Typography>
                        <Popover
                            anchor={<ButtonIcon name={"More"} circular viewType={"text"} disabled={disbaledMore} />}
                            ref={refPopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <Button
                                viewType={"text"}
                                labelProps={{ type: "p1" }}
                                style={{ display: "block", width: "100%", textAlign: "left", textTransform: "unset" }}
                                label={Config.lang("Xuat_excel1")}
                                onClick={onExportExcel}
                            />
                            <Button
                                viewType={"text"}
                                labelProps={{ type: "p1" }}
                                style={{ display: "block", width: "100%", textAlign: "left", textTransform: "unset" }}
                                label={Config.lang("InU")}
                                disabled={isLoadingPrint}
                                onClick={onPrint}
                            />
                            {/*Bổ sung menu động cho cảnhbáo có AlertBaseID = ‘D82A5103’, ‘D82A5104’*/}
                            {["D82A5103", "D82A5104"].includes(data?.AlertBaseID) && (
                                <Button
                                    viewType={"text"}
                                    labelProps={{ type: "p1" }}
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        textAlign: "left",
                                        textTransform: "unset",
                                    }}
                                    label={Config.lang("Lap_de_xuat_cap_phat_CCLD")}
                                    disabled={!permission["W51F2200"]}
                                    onClick={issueEquipment}
                                />
                            )}
                        </Popover>
                    </div>
                    <Row>
                        <Col xs={12}>
                            <GridContainer
                                reference={ref => (refGrid.current = ref)}
                                loading={gridLoading}
                                style={{ border: "none" }}
                                keyExpr={"ID"}
                                dataSource={dataGrid}
                                itemPerPage={20}
                                selection={{
                                    allowSelectAll: false,
                                    mode: "multiple",
                                    selectAllMode: "allPages",
                                    showCheckBoxesMode: "always",
                                }}
                                filterRow={{
                                    visible: true,
                                    showOperationChooser: false,
                                }}
                                onSelectionChanged={onSelectionChanged}
                                height={Config.getHeightGrid() - 100}
                                typePaging={"normal"}
                                onContentReady={onContentReady}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                pagerFullScreen={false}
                                gridProps={{
                                    onExporting: e => {
                                        e.fileName = `${data?.AlertMessage ?? ""}` + new Date().getTime();
                                    },
                                }}
                            >
                                {dataColumn.map((column, idx) => {
                                    let type = "";
                                    switch (column.FieldType) {
                                        case "S":
                                            type = "string";
                                            break;
                                        case "N":
                                            type = "number";
                                            break;
                                        case "D":
                                            type = "datetime";
                                            break;
                                        default:
                                            break;
                                    }
                                    if (column.FieldName === "IsUsed") {
                                        return (
                                            <Column
                                                key={idx}
                                                type="selection"
                                                dataType={type}
                                                dataField={column.FieldName}
                                                visibleIndex={column.OrderNum}
                                                alignment={"center"}
                                                minWidth={column.FieldWidth}
                                                visible={column.ColumnType !== "H"}
                                                fixed={!isLoadingPrint ? hasFixedColumn.current : false}
                                                headerCellRender={() => renderHeaderCell(column)}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Column
                                                key={idx}
                                                format={type === "datetime" ? "dd/MM/yyyy" : ""}
                                                visibleIndex={column.OrderNum}
                                                dataField={column.FieldName}
                                                caption={column.FieldCaption}
                                                minWidth={column.FieldWidth}
                                                dataType={type}
                                                visible={column.ColumnType !== "H"}
                                                fixed={!isLoadingPrint ? column.ControlFormat === "F" : false}
                                            />
                                        );
                                    }
                                })}
                            </GridContainer>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

W82F1000.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
};

W82F1000.defaultProps = {
    open: false,
    onClose: null,
    data: {},
};

export default W82F1000;
