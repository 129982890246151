import React, {useEffect, useState, useRef} from 'react';
import {Modal, ModalBody, ModalHeader, Button, ButtonIcon, Dropdown} from "diginet-core-ui/components";
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import {Column, CustomRule} from "devextreme-react/data-grid";
import _ from "lodash";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FormGroup} from "react-bootstrap";
import * as w75F8003Actions from "../../../../redux/W75/W75F8003/W75F8003_actions";

const W75F8003 = ({open, data, onClose, w75F8003Actions, iPermission}) => {
    const userList = _.filter(Config.listUsers, item => !!item.UserID);
    const [dataGrid, setDataGrid] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const {
        TransID = "",
        ApprovalFlowID = "",
        ApprovalLevel = "",
        ObjectID = ""
    } = _.pick(data, ["TransID", "ApprovalFlowID", "ApprovalLevel", "ObjectID"]);

    let refGrid = null;
    let dataSourceListUser = useRef([...userList]);
    let userSelected = useRef([]);

    useEffect(() => {
        setLoading(true);
        w75F8003Actions.loadForm({TransID, ApprovalFlowID, ApprovalLevel}, (err, data) => {
            setLoading(false);
            if (err) return Config.popup.show("ERROR", err);
            if (data) {
                setDataGrid(_(data).map(Config.getUser).filter(_.size).map(item => ({
                    ..._.pick(item, ["EmployeeID", "UserID", "UserName", "DutyName", "DepartmentName"]),
                    isError: false,
                    isNew: false,
                    isUpdated: false
                })).value());
                dataSourceListUser.current = _.filter(dataSourceListUser.current, item => !_.includes(_.map(data, "UserID"), item.UserID));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIndexUser = empID => _.findIndex(dataGrid, ["EmployeeID", empID]);

    const setUserSelected = (oldUserID, newUserID = "") => {
        if (_.some(userSelected.current, userID => userID === oldUserID)) userSelected.current = _.filter(userSelected.current, elm => elm !== oldUserID)
        if(newUserID) userSelected.current = _.uniq([...userSelected.current, newUserID]);
    }

    const onInsertNewRow = () => refGrid && refGrid.instance.addRow();

    const onSaveRow = (data = {}) => {
        if (!data?.UserID) return false;
        const indexUser = getIndexUser(data.EmployeeID);
        let isError = false;
        const params = {
            UserIDGrid: data?.UserID ?? "",
            ObjectID,
            TransID,
            ApprovalFlowID,
            ApprovalLevel
        }
        setLoading(true);
        w75F8003Actions.saveRow(params, err => {
            setLoading(false);
            if (err) {
                isError = true;
                Config.popup.show("ERROR", err);
            }
            if (!isError) Config.popup.show("success", Config.lang("Luu_thanh_cong"));

            setDataGrid([
                ..._.slice(dataGrid, 0, indexUser),
                {
                    ...dataGrid[indexUser],
                    isUpdated: false,
                    isNew: false,
                    isError
                },
                ..._.slice(dataGrid, indexUser + 1)
            ]);
        })
    };

    const onDeleteRow = (data = {}) => {
        if (!data?.UserID) return false;
        let userRemove;
        const params = {
            UserIDGrid: data?.UserID || "",
            TransID,
            ApprovalFlowID,
            ApprovalLevel
        }
        const indexUser = getIndexUser(data.EmployeeID);
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            setLoading(true);
            w75F8003Actions.deleteRow(params, err => {
                setLoading(false);
                if (err) {
                    userRemove =  {
                        ...dataGrid[indexUser],
                        isUpdated: false,
                        isError: true
                    };
                    Config.popup.show("ERROR", err);
                }
                if (!userRemove) {
                    Config.popup.show("success", Config.lang("Xoa_thanh_cong"));
                    setUserSelected(data?.UserID);
                }
                setDataGrid([
                    ..._.slice(dataGrid, 0, indexUser),
                    userRemove,
                    ..._.slice(dataGrid, indexUser + 1)
                ]);
            })
        })
    };

    const renderAction = ({data = {}}) => _.map([{
        name: "Save",
        disabled: iPermission < 3 || ((data.isUpdated ? !data.isUpdated : !data.isNew) || false),
        onClick: () => onSaveRow(data),
    }, {
        name: "Delete",
        disabled: iPermission < 4 || ((data.isUpdated ? data.isUpdated : data.isNew || data.isError) || false),
        onClick: () => onDeleteRow(data),
    }
    ], (props, i) => <ButtonIcon key={i} circular viewType={"text"} {...props}/>);

    const cellRender = ({data}) => _([...dataSourceListUser.current, data]).filter(["UserID", _.get(data, "UserID", "")]).get("[0].UserName", "")

    const editCellRender = (e) => {
        const {data = {}, rowIndex, component} = e;
        let getDataUserSelected;
        const dataSource = _.filter(dataSourceListUser.current, item => !_.includes(userSelected.current, item.UserID));
        if(data?.UserID) getDataUserSelected = _.find(userList,["UserID", data.UserID]);
        return <Dropdown
            dataSource={_.size(getDataUserSelected) ? [getDataUserSelected,...dataSource] : dataSource}
            displayExpr={"UserName"}
            valueExpr={"UserID"}
            defaultValue={_.get(getDataUserSelected,"UserID", "")}
            stylingMode="outlined"
            closeAfterSelect
            allowSearch
            onChange={(ev) => {
                const userID = _.get(ev, "value", "");
                const {DutyName = "", DepartmentName = ""} = _(dataSourceListUser.current).filter(["UserID", userID]).get("[0]", {});
                e.setValue(userID);
                component.cellValue(rowIndex, "DutyName", DutyName);
                component.cellValue(rowIndex, "DepartmentName", DepartmentName);
                setUserSelected(data.UserID, userID);
                component.closeEditCell();
            }}
        />
    };

    return <Modal open={open} pressESCToClose={false} onClose={() => onClose("showW75F8003", true)}>
        <ModalHeader title={Config.lang("Thay_doi_nguoi_duyet")}/>
        <ModalBody>
            <FormGroup>
                <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <Button startIcon={"AddCircle"}
                            color={"primary"}
                            viewType={"filled"}
                            onClick={onInsertNewRow}
                            text={Config.lang("Them_nguoi_duyet")}/>
                </div>
            </FormGroup>
            <GridContainer
                reference={ref => refGrid = ref}
                rowAlternationEnabled={false}
                style={{border: 'none'}}
                showRowLines={false}
                typePaging={"normal"}
                keyExpr={"EmployeeID"}
                dataSource={dataGrid}
                showBorders={false}
                showColumnLines={false}
                noDataText={Config.lang("No_data")}
                loading={isLoading}
                height={Config.getHeightGrid() - 180}
                pagerFullScreen={false}
                selection={{mode: "none"}}
                editing={{
                    mode: 'cell',
                    refreshMode: 'reshape',
                    allowUpdating: true,
                    startEditAction: "click"
                }}
                onInitNewRow={e => {
                    e.data.isNew = true;
                    e.data.isUpdated = false;
                    e.data.isError = false;
                }}
                onRowUpdated={(e) => {
                    e.data.isUpdated = true;
                    e.data.isError = false;
                    setTimeout(() => e.component.saveEditData(), 300)
                }}
                onRowPrepared={(e) => {
                    if (!e) return false;
                    const {data} = e;
                    e.rowElement.style.backgroundColor = "none";
                    if (data?.isUpdated) e.rowElement.style.backgroundColor = "rgba(139, 195, 74, 0.32)";
                    if (data?.isError) e.rowElement.style.backgroundColor = "rgb(217,83,79, 0.32)";
                    if (data?.isNew) e.rowElement.style.backgroundColor = "rgb(100, 149, 237, 0.32)";
                }}
                allowColumnResizing={true}
            >
                <Column
                    alignment={"center"}
                    allowEditing={false}
                    visible={Config.isMobile}
                    caption={Config.lang("Hanh_dong")}
                    cellRender={renderAction}
                />
                <Column
                        width={400}
                        dataField={"UserID"}
                        caption={Config.lang("Nguoi_dung")}
                        editCellRender={editCellRender}
                        cellRender={cellRender}
                >
                    <CustomRule
                        validationCallback={e => !!e.data.UserID}
                        message="Giá trị bắt buộc nhập"/>
                </Column>
                <Column dataField={"isNew"} defaultSortOrder={"desc"} visible={false}/>
                <Column width={260} allowEditing={false} dataField={"DutyName"}  caption={Config.lang("Chuc_vu")}/>
                <Column width={260} allowEditing={false} dataField={"DepartmentName"} caption={Config.lang("Phong_ban")}/>
                <Column
                    fixed={true}
                    allowEditing={false}
                    alignment={"center"}
                    width={200}
                    fixedPosition={"right"}
                    visible={!Config.isMobile}
                    cellRender={renderAction}
                />
            </GridContainer>
        </ModalBody>
    </Modal>
};

export default connect((state) => (
    {
        getForm: state.W75F8003.getForm,
    }), (dispatch) => (
    {
        w75F8003Actions: bindActionCreators(w75F8003Actions, dispatch),
    }
))(W75F8003)